import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Descriptions, Divider, Flex, Layout, Popover, Row, Space, Table, Tooltip, Typography } from "antd";
import { isPermissionExist, StatusTag, useAuthState } from "../../../../common";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CostingInvoice } from "../../costing-invoice";
import { EditFilled, InfoCircleOutlined } from "@ant-design/icons";
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { useEffect, useState } from "react";
// import { PerformaProps } from "../../coal-costings";
import queryString from 'query-string';
import { BargeCostingIdReqDto, BargeCostingsService, ContractModesEnum, ContractStatusEnum, CostingTypesEnum, PriceTypeEnum, ReferenceFeatures } from "@exportx/shared-models-and-services";
import { useLocation, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import moment from "moment";
import { QualityPriceAdjustmentsDeatils } from "../../costing-detail-view";
import AddInvoiceDrawer from "../../add-invoice-drawer";


interface PerformaProps {
  referenceFeatures: ReferenceFeatures,
}


export const BargeCostingDetailsView = (props: PerformaProps) => {

  const { referenceFeatures } = props;
  const { authContext } = useAuthState();
  const [data, setFormValues] = useState<any>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>()

  const { Text } = Typography;
  const navigate = useNavigate()
  const location = useLocation();
  const [advTotal, setAdvTotal] = useState<number>(0)
  const [bargeData, setBargeData] = useState<any>([]);
  const [costingIds, setCostingId] = useState<any>()
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [accessId, setAccessId] = useState<any>({});
  const paramBargeId: any = queryString.parse(location.search)?.costing_id
  let { costingId } = paramBargeId != null ? { costingId: paramBargeId } : { costingId: null };
  const service = new BargeCostingsService();

  let path = `barge-costings/barge-creation`;

  useEffect(()=>{
    if(!isPermissionExist([396])){
      return navigate('/')
    }
  },[])

  useEffect(() => {
    setAccessId({
      create: 404,
      view: 405,
      update: 406,
      delete: 407,
      approve: 408,
      reject: 409,
      release: 410,
      files: 411,
      bargeEdit: 397,
      bargeDelete: 398,
    });
  }, []);

  useEffect(() => {
    bgCostingData()
  }, [costingId])

  const bgCostingData = () => {
    service.getBargeCostingDataByCostingId(new BargeCostingIdReqDto(costingId, authContext.defaultPlant)).then(res => {
      if (res.status) {
        const costings = res.data?.bargeCostings[0] || {}
        setFormValues({ ...res.data, ...costings });
        const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
        setBargeData(res.data?.bargeCostings?.map((item) => ({ ...item, bargeVendor: res.data?.bargeSupplier, coalVendor: res.data?.coalSupplier, remaining: res.data?.totalAmount, businessNo: res?.data?.businessNo })))
        setAdvTotal(advTotal)
      } else {
        setFormValues(null);
      }
    }).catch(err => {
      console.log(err)
      setFormValues(null);
    });
  }
  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: bargeData.length }
    else return { rowSpan: 0 }
  };

  const editHandler = () => {
    navigate(`/${path}?costingId=${costingId}`);
  }

  const adjustedQty = bargeData?.reduce(
    (total, item) => total + Number(item.inWardQty), 0);


  const footer = () => (
  <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
          <div style={{marginRight: '35px'}}>
              <div><b>Adjust Quantity:</b></div>
          </div>
          <div style={{marginRight: '10px'}}>
              <div style={{color: '#0283C8'}}><b>{Number(adjustedQty)?.toFixed(3)}</b></div> 
          </div>
      </div>
  </div>
  );

  const columns: any = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      render: (value, record) => {
        if (record.bargeId) {
          const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a>
            <Typography className="small-text"> {record.bargeNomination}</Typography>
          </div>

        }
        return <div>
          <Text className="small-text"> {value}</Text>
          <Typography className="small-text"> {record.bargeNomination}</Typography>
        </div>

      }
    },
    {
      title: 'Barge Vendor',
      dataIndex: 'bargeVendor',
      key: 'bargeVendor',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Jetty',
      dataIndex: 'loadingJetty',
      key: 'loadingJetty',
      width: 100,
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Supplier',
      dataIndex: 'coalVendor',
      key: 'coalVendor',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Grade',
      dataIndex: 'incoterm',
      key: 'incoterm',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      width: 80,
      render: (value, record) => <Typography className="small-text">{Number(value).toFixed(3) ?? 'N/A'}</Typography>

      // onCell: sharedOnCell,
    },
    {
      title: data?.bargingType === PriceTypeEnum.PER_MT ? 'price PMT' : 'Lumpsum',
      dataIndex: 'basePrice',
      key: 'basePrice',
      width: 130,
      align: 'right',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.basePrice, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Total',
      dataIndex: 'remaining',
      key: 'remaining',
      width: 130,
      align: 'right',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      width: 130,
      align: 'right',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.paid, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Balance',
      dataIndex: 'remaining',
      key: 'remaining',
      width: 130,
      align: 'right',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>
      </>

    },
  ];

  const columns2: any = [
    {
      title: "Advance Type",
      dataIndex: "advanceType"
    },
    {
      title: "Advance Balance",
      dataIndex: "totalAdjustedAmount",
      align: 'right',
      render: (value, record) => {
        return <span>{Number(value).toFixed(3)}</span>
      }
    },
    {
      title: "Costing No",
      dataIndex: "adjustedCostingNo"
    },
    {
      title: "Adjusted Amount",
      dataIndex: "adjustAmount",
      align: 'right',
      render: (value, record) => {
        return <span>{Number(value).toFixed(3)}</span>
      }
    },
  ]

    return (
      <>
      <Layout style={{ backgroundColor: '#F5F5F5', border: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'none',
            marginBottom: '10px',
          }}
        >
          <Space size={5}>
            <a
              href={`/#/barge-costings/barge`}
              style={{ fontSize: '18px', color: '#6B7280' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}
            >
              {data?.costingNo}
            </span>
            &nbsp;
            <StatusTag status={data?.approvalStatus} />
          </Space>
          <Space size={10}>

            {isPermissionExist([901]) && (<PDFDownloadLink
                  document={<CostingInvoice costingDetails={data} costingName={referenceFeatures}/>}
                  fileName={`${data?.costingNo}.pdf`}
              >
                  <Tooltip placement="topRight" title="Costing Invoice">
                      <FontAwesomeIcon 
                          style={{
                              border: 'none',
                              fontSize: '15px',
                              padding: '7px',
                              borderRadius: '50%',
                              color: '#0295D4',
                              backgroundColor: '#DEECF3',
                          }}
                          icon={faDownload} onClick={() => {
                              setDummyRefresh(prev => prev + 1)
                          }}
                      />
                  </Tooltip>
              </PDFDownloadLink>)}

            {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(data?.approvalStatus) && isPermissionExist([397]) && (<EditFilled
              style={{
                  border: 'none',
                  fontSize: '15px',
                  padding: '7px',
                  borderRadius: '50%',
                  color: '#0295D4',
                  backgroundColor: '#DEECF3',
                }} 
              onClick={editHandler}/>)}
            {isPermissionExist([405]) && <Button type="primary" htmlType="submit" className="new-button" onClick={() => {
                            setDrawerOpen(true);
                            setCostingId((prev) => { return { ...prev, costingId: data.costingId, totalAmount: data.totalSumAmount } });
                            setDummyRefresh(prev => prev + 1);
                        }}>
              Invoices
            </Button>}
          </Space>
        </div>
        
        <Row gutter={8}>
          <Col span={16} xs={24} md={24} lg={16}>
            <Col span={10} style={{ display: 'flex' }} xs={24} md={24} lg={24}>
              <Card
                title={'Barge Costing Details'}
                headStyle={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#323F49',
                  border: 'none',
                  paddingTop: '10px',
                }}
                bordered={false}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                }}
              >
                <Row>
                  <Col span={16} xs={24} md={24} lg={24}>
                    <Descriptions
                      className="descriptions-label"
                      title=""
                      column={{
                        xxl: 4,
                        xl: 4,
                        lg: 4,
                        md: 3,
                        sm: 1,
                        xs: 1,
                      }}
                      layout="vertical"
                      colon={false}
                    >


                      <Descriptions.Item label={'Business Number'}>
                        <span className="descriptions">
                          <a
                            style={{ color: '#0083C9' }}
                            href={`/#/bn-detail-view?bn_Id=${data?.businessNo}`}
                          >
                            {data?.bpName ? data?.bpName : "--"}
                          </a>
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Barge Supplier">
                        <span className="descriptions">{data?.bargeSupplier || "--"}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Coal Supplier">
                        <span className="descriptions">{data?.coalSupplier || "--"}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Transaction Date">
                        <span className="descriptions">
                          {`${moment(data?.costingDate).format('DD-MMM-YYYY')}`}
                        </span>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              style={{ margin: '12px 0px ' }}
            >
              <Card
                className="card-shadow"
                styles={{ body: { padding: '0px 10px 15px' } }}
                style={{ marginBottom: 8 }}
                title={<h6>Barges</h6>}
              >
                <Table
                  className="contracts-list"
                  dataSource={bargeData}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 1200 }}
                  footer={footer}
                />

              </Card>
            </Col>

            {/* <Col
              style={{ margin: '12px 0px ' }}
            >
              <QualityPriceAdjustmentsDeatils contractType={referenceFeatures} selectedRowData={bargeDetails[0]} formInitialValues={data}/>
            </Col> */}

            <Col
              style={{ margin: '12px 0px ' }}
            >
              <Card
                className="card-shadow"
                styles={{ body: { padding: '0px 10px 15px' } }}
                style={{ marginBottom: 8 }}
                title={<h6>Advance Adjustment</h6>}
              >
                <Table
                  className="contracts-list"
                  dataSource={data?.advanceTarnsactionsData}
                  columns={columns2}
                  pagination={false}
                  // scroll={{ x: 1200 }}costing
                />
              </Card>
            </Col>

            {/* {children} */}
          </Col>

          

          <Col span={8} xs={24} md={24} lg={8} >
              <Card
                className="card-shadow"
                title={<span className="card-title-md">Price Adjustment</span>}
                styles={{ body: { padding: '6px 14px' } }}
              >
                {bargeData.length && (<Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Currency Type</Text>
                  <Text className="price-card-text"> {bargeData[0]?.currencyType ?? 'N/A'}</Text>
                </Flex>)}
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Exchange Date</Text>
                  <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Exchange Rate</Text>
                  <Text className="price-card-text">{data?.exchangeRate} {data?.quoteCurrency}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Base Freight</Text>
                  <Text className="price-card-text">{Number(data?.baseFreight).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Dead Freight</Text>
                  <Text className="price-card-text">{Number(data?.deadFreight).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-heading">Total Amount</Text>
                  <Text className="price-card-heading">{Number(data?.costingsTotal).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>
                <Divider dashed={true} />

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">VAT</Text>
                  <Text className="price-card-text">{`${data?.vat ? 'Yes' : 'No'}`}</Text>
                  <Text className="price-card-text">{Number(data?.taxTotal).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">TDS</Text>
                  <Text className="price-card-text">{`${data?.tdsName || ''} (${data?.tdsPercentage || ''})`}</Text>
                  <Text className="price-card-text">{Number(data?.tdsTotal).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>

                <Divider dashed={true} />

                {data?.advanceTarnsactionsData?.map((adv) => (

                  <Flex justify="space-between" align='center' key={adv.id}>
                    <Text className="price-card-text">{`Deduction (${adv.adjustedCostingNo}) `}</Text>
                    <Text className="price-card-text">{Number(adv?.totalAdjustedAmount).toFixed(3)} {data?.billingCurrency}</Text>
                  </Flex>

                ))}

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Total Advance Adjustment</Text>
                  <Text className="price-card-text">{Number(advTotal).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-green">To be paid to Supplier</Text>
                  <Text className="price-card-green">{Number(data?.totalAmount - advTotal).toFixed(3)} {data?.billingCurrency}</Text>
                </Flex>

              </Card>
            </Col>
          </Row>
        </Layout>
        <AddInvoiceDrawer referenceFeatures={ReferenceFeatures.BARGE_CPC_COSTING} key={dummyRefresh} setDummyRefresh={setDummyRefresh} openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingIds} accessId={accessId} />
      </>
    )
}

export default BargeCostingDetailsView