// import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import CostingBasicDetails from "./costing-invoice";
import InvoiceNO from "./invoice-no";
import BargeInvoice from "./barge-invoice";
import { ReferenceFeatures } from "@exportx/shared-models-and-services";
import moment from "moment";
import { useAuthState } from "../../../common";
import { useState } from "react";
// import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family"

interface InvoiceIProps {
  costingDetails?: any,
  costingName?: ReferenceFeatures,
}


export const CostingInvoice = (props: InvoiceIProps) => {
  const { costingDetails, costingName } = props;
  let invoiceName: any = ''

  if(costingName === ReferenceFeatures.PERFORMA_PC_COSTING){
    invoiceName = 'Performa Invoice (Coal Purchase)'
  } else if(costingName === ReferenceFeatures.PERFORMA_SC_COSTING){
    invoiceName = 'Performa Invoice (Coal Sales)'
  } else if(costingName === ReferenceFeatures.COMMERCIAL_PC_COSTING){
    invoiceName = 'Commercial Invoice (Coal Purchase)'
  } else if(costingName === ReferenceFeatures.COMMERCIAL_SC_COSTING){
    invoiceName = 'Commercial Invoice (Coal Sales)'
  } else if(costingName === ReferenceFeatures.MARKETING_FEES){
    invoiceName = 'Marketing Fees'
  } else if(costingName === ReferenceFeatures.PROCUREMENT_FEES){
    invoiceName = 'Procurement Fees'
  } else if(costingName === ReferenceFeatures.BARGE_COSTINGS){
    invoiceName = 'Barge Costing'
  } else if(costingName === ReferenceFeatures.EXPENSE_ENTRY){
    invoiceName = 'Expense Entry'
  } else if(costingName === ReferenceFeatures.MV_DEAD_FREIGHT){
    invoiceName = 'Dead Freight'
  } else if(costingName === ReferenceFeatures.MV_DEMURRAGE){
    invoiceName = 'Demurrage'
  } else if(costingName === ReferenceFeatures.CREDIT_NOTE){
    invoiceName = 'Credit Note'
  } else if(costingName === ReferenceFeatures.DEBIT_NOTE){
    invoiceName = 'Debit Note'
  } else {
    invoiceName = costingName
  }
  
  let advTotal;
  if(costingDetails?.advanceTarnsactionsData){
    advTotal = Number(
      costingDetails?.advanceTarnsactionsData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2),
    );
  }

  let barge =  costingDetails?.costingTransactions || costingDetails?.transactionBarges || costingDetails?.bargeCostings || costingDetails?.expenseEntryTransactions

  

  const styles = StyleSheet.create({
    page: {
      width: "100%",
      height: "100vh",
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingTop: 20,
      paddingLeft: 25,
      paddingRight: 25,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    amount: {
      paddingHorizontal: 8, 
      paddingVertical: 4,
      flexDirection: "row", 
      alignItems: "center", 
      justifyContent: 'space-between'
    }
});
  return <>

        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{
                      borderBottom: 0.65,
                      borderBottomColor: "black",
                    }}
                >
                  <Text style={{ textAlign: "center", fontSize: 16, paddingBottom: 8 }}>{invoiceName}</Text>
                </View>

                <CostingBasicDetails costing={costingDetails} costingName={costingName} />
                {Array.isArray(barge) && barge.length <= 0 &&(
                   <View style={{ width: "100%", borderBottomWidth: 1, borderBottomColor: "#ddd" }}></View>
                )}

                {!barge && (
                   <View style={{ width: "100%", marginTop: 10, borderBottomWidth: 1, borderBottomColor: "#ddd" }}></View>
                )}

                {Array.isArray(barge) && barge.length > 0 &&(
                  <BargeInvoice BargeInvoice={costingDetails} costingName={costingName} />
                )}


                {(costingName === ReferenceFeatures.MARKETING_FEES || costingName === ReferenceFeatures.PROCUREMENT_FEES) && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {Array.isArray(barge) && barge.length > 0 && (<View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Currency Type</Text>
                          <Text style={{ textAlign: 'right' }}>{barge[0]?.currencyType ? barge[0]?.currencyType  : "N/A"}</Text>
                        </View>)}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeRate} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Price After Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.exchangeRateValue).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Coal Price</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.adjustedPriceValue).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Fc Charges</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.fcCharges).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 1</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName1}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(Number().toFixed(3)).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 2</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName2}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal2).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.tdsTotal).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalPayable).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(advTotal).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ backgroundColor: "#E3F2EB" }]}>
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.invoiceTotal).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                    </View>
                </View>)}

                {(costingName === ReferenceFeatures.COMMERCIAL_PC_COSTING || costingName === ReferenceFeatures.COMMERCIAL_SC_COSTING || costingName === ReferenceFeatures.PERFORMA_PC_COSTING || costingName === ReferenceFeatures.PERFORMA_SC_COSTING || costingName === ReferenceFeatures.SALES_FREIGHT_COSTING) && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {Array.isArray(barge) && barge.length > 0 && (<View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Currency Type</Text>
                          <Text style={{ textAlign: 'right' }}>{barge[0]?.currencyType ? barge[0]?.currencyType  : "N/A"}</Text>
                        </View>)}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeRate} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.costingsTotal).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalPayable).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 1</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName1}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal1).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 2</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName2}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal2).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>Number().toFixed(3)
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.tdsTotal).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(advTotal).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ backgroundColor: "#E3F2EB" }]}>
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount || costingDetails?.invoiceTotal).toFixed(3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                    </View>
                </View>)}

                {costingName === ReferenceFeatures.BARGE_COSTINGS && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {Array.isArray(barge) && barge.length > 0 && (<View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Currency Type</Text>
                          <Text style={{ textAlign: 'right' }}>{barge[0]?.currencyType ? barge[0]?.currencyType  : "N/A"}</Text>
                        </View>)}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.exchangeRate).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Base Freight</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.baseFreight).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Dead Freight</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.deadFreight).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.costingsTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>VAT</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.vat ? 'Yes' : 'No'}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName || ''} {(costingDetails?.tdsPercentage || '')}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.tdsTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        {costingDetails?.advanceTarnsactionsData?.map((adv) => (
                           <View style={styles.amount} key={adv.id}>
                           <Text style={{ textAlign: 'right' }}>Deduction ({adv.adjustedCostingNo})</Text>
                           <Text style={{ textAlign: 'right' }}>{Number(adv?.totalAdjustedAmount).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                         </View>
                        ))}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(advTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#E3F2EB" */}
                        <View style={[styles.amount, { color: "green" }]}> 
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount - advTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#FFC0C0" */}
                        {/* <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Remaining Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount - advTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View> */}
                    </View>
                </View>)}

                {costingName === ReferenceFeatures.EXPENSE_ENTRY && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeRateValue} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.total).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>VAT</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.vatApplicable ? 'Yes' : 'No'}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.vat).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName || ''} {(costingDetails?.tdsPercent || '')}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.tds).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        {costingDetails?.advanceTarnsactionsData?.map((adv) => (
                           <View style={styles.amount} key={adv.id}>
                           <Text style={{ textAlign: 'right' }}>Deduction ({adv.adjustedCostingNo})</Text>
                           <Text style={{ textAlign: 'right' }}>{Number(adv?.totalAdjustedAmount).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                         </View>
                        ))}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(advTotal).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#E3F2EB" */}
                        <View style={[styles.amount, { color: "green" }]}> 
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount - advTotal).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#FFC0C0" */}
                        {/* <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Remaining Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount - advTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View> */}
                    </View>
                </View>)}

                {(costingName === ReferenceFeatures.MV_DEAD_FREIGHT || costingName === ReferenceFeatures.MV_DEMURRAGE) && ( 
                  <View style={{ width: "100%", display: 'flex', justifyContent: "space-between",marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    {costingName !== ReferenceFeatures.MV_DEMURRAGE && (
                      <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                        <Text>Thanks For Your Business</Text>
                      </View>
                    )}
                    {costingName === ReferenceFeatures.MV_DEMURRAGE && (
                    <View style={{ width: "48%", height: "auto", padding: 8, backgroundColor: "#f2f7fd", borderRadius: 5 }}>
                        <>
                          <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Demurrage/Despatch Details</Text>
                            <Text style={{ textAlign: 'right' }}></Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Mv Stowage Plan</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.stowagePlanInMt}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Contract No</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.contractNo}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Shipping Type</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.shippingType}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Supply Tonnage</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.supplyTonnage}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Demurrage/Dispatch Applicable</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.costingApplicable ? "Yes" : "No"}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Notes</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.notes}</Text>
                          </View>
                        </>
                      </View>
                      )}
                    <View style={{ width: "48%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {costingName === ReferenceFeatures.MV_DEAD_FREIGHT && (
                          <>
                            <View style={[styles.amount,{ fontSize: 9 }]}>
                              <Text style={{ textAlign: 'right' }}>Dead Freight</Text>
                              <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.deadFreight).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                            </View>
                            <View style={[styles.amount,{ fontSize: 9 }]}>
                              <Text style={{ textAlign: 'right' }}>Price</Text>
                              <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.pricePerMT).toFixed(3)} {costingDetails?.quoteCurrency}</Text>
                            </View>
                          </>
                        )}
                        {costingName === ReferenceFeatures.MV_DEMURRAGE && (
                          <>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Load Rate Per Day</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.portRate} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Demurrage Rate Per Day</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.demurrgeRatePerDay} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Dispath Rate Per Day</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.dispatchRatePerDay} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Allowed Time To Load</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.allowedTimeToLoad} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Time Completed To Load</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.timeCompletedForLoadMV} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>No Of Diff Days</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.noofDaysDifference} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                        </>
                        )}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeRate} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.amount || costingDetails?.cost).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Discount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.discount).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Amount After Discount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.amtAftDiscount).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 1</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName1}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal1).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 2</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName2}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal2).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.tdsTotal).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#E3F2EB" */}
                        <View style={[styles.amount, { color: "green" }]}> 
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount).toFixed(3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#FFC0C0" */}
                        {/* <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Remaining Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.totalAmount} {costingDetails?.billingCurrency}</Text>
                        </View> */}
                    </View>
                </View>)}

                {(costingName === ReferenceFeatures.CREDIT_NOTE || costingName === ReferenceFeatures.DEBIT_NOTE) && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>VAT</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.vatApplicable ? 'Yes' : 'No'}</Text>
                          {/* <Text style={{ textAlign: 'right' }}>0</Text> */}
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax Total</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxPercentage}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.taxTotal).toFixed(3)}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsPercentage}</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.tdsTotal).toFixed(3)}</Text>
                        </View>
                        <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount).toFixed(3)}</Text>
                        </View>
                    </View>
                </View>)}

                {/* <InvoiceNO invoice={costingDetails}/> */}
            </Page>
            {Array.isArray(costingDetails?.invoices) && costingDetails?.invoices.length > 0 && (
              <Page size="A4" style={styles.page}>
                <InvoiceNO invoice={costingDetails} />
              </Page>
          )}
        </Document>

    </>
}

export default CostingInvoice;