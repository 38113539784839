import {
  AdvanceEnumType,
  AdvanceTypeReqDto,
  CalculationFilterDataReq,
  CalculationTableDto,
  CommercialCoalCostingService,
  CommercialGetAllDto,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTermEnum,
  CostingTypesEnum,
  CostingWorkLogsEnum,
  PerFormaReqIdDto,
  PerformaAdvanceDataDto,
  ProcurementContractTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Form,
  Input,
  Row,
  Tabs,
} from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { CoalCostingFilterComponent, SavedCostingsGrid } from '../common';
import CmAdvanceAdjustments from './cm-pc-advance-adjustments';
import CommercialCostingsBargeSelection from '../common/cm-coal-costings-bg-selection';
import CommercialPriceAdjustment from './cm-pc-price-adjustment';
import { getNumberFromLocalString } from '@exportx/ui-utils';
import TabPane from 'antd/es/tabs/TabPane';
import { BargeCostingApproval } from '../../barge-costings';

interface IPropsCommercialCoalCostings {
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
  pcrContractType?: ProcurementContractTypeEnum;
  costingType?: CostingTypesEnum;
}

export const CmCoalCostingMainForm = (props: IPropsCommercialCoalCostings) => {
  const [formRef] = Form.useForm();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<CommercialGetAllDto>();
  const { contractType, referenceFeatures, pcrContractType, costingType } =
    props;
  const [bargeData, setBargeData] = useState<CalculationTableDto[]>([]);
  const [totalBargeQuantity, setTotalBargeQuantity] = useState<any>();
  const [bargeSelection, setBargeSelection] = useState<any>([]);
  const [bargeSelectionRowKeys, setBargeSelectionRowKeys] = useState<any[]>([]);
  const cmService = new CommercialCoalCostingService();
  const { authContext } = useAuthState();
  const [cmCostingId, setCmCostingId] = useState<string>();
  const [performaAdvance, setPerformaAdvance] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  let location = useLocation();
  let paramsId: any = queryString.parse(location.search)?.costingId;
  const [qualityFieldValue, setQualityFieldValue] = useState<number>();
  const [priceAdjustmentDrawer, setPriceAdjustmentDrawer] =
    useState<boolean>(false);
  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [gridDrawer, setGridDrawer] = useState<boolean>(false);
  const [invoiceContracts, setInvoiceContracts] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [accessId, setAccessId] = useState({});
  const [createPermission, setCreatePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  const [taxTotal, setTaxTotal] = useState<any>({
    taxTotal1: 0,
    taxTotal2: 0,
    tdsTotal: 0,
    invoiceTotal: 0,
    exchangeRateValue: 0,
    fcTotal: 0,
    fcCharges: 0,
    freightPricePmt: 0,
    totalCoalPrice: 0,
  });

  useEffect(() => {
    let permission;
    if (contractType === ContractModesEnum.PURCHASE) {
      permission = [292];
      setViewPermission(isPermissionExist([292]));
      setCreatePermission(isPermissionExist([291]));
      setAccessId({
        create: 300,
        view: 301,
        update: 302,
        delete: 303,
        approve: 304,
        reject: 305,
        release: 306,
        files: 307,
        purchaseEdit: 293,
      });
    } else {
      permission = [334];
      setViewPermission(isPermissionExist([334]));
      setCreatePermission(isPermissionExist([333]));
      setAccessId({
        create: 342,
        view: 343,
        update: 344,
        delete: 345,
        approve: 346,
        reject: 347,
        release: 348,
        files: 349,
        purchaseEdit: 335,
      });
    }
    if (!isPermissionExist(permission)) navigate('/');
  }, [contractType]);

  useEffect(() => {
    if (cmCostingId) {
      getAllCommercialData(cmCostingId);
    } else if (paramsId) {
      getAllCommercialData(paramsId);
    }
  }, [cmCostingId || paramsId]);

  const pcCommercialBargeData = (values) => {
    values.contractType = contractType;
    cmService
      .getBargesDataForCommercial(values)
      .then((res) => {
        if (res.status) {
          setBargeData(res.data);
          // setPerformaAdvance(res.perFormaData);
        } else {
          setBargeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setBargeData([]);
      });
  };

  const scCommercialBargeData = (values) => {
    cmService
      .getBargesDataForSalesCommercial(values)
      .then((res) => {
        if (res.status) {
          setBargeData(res.data);
        } else {
          setBargeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setBargeData([]);
      });
  };

  const loadBargesOnClickHandler = (values) => {
    const req = new CalculationFilterDataReq(
      authContext.defaultPlant,
      values.businessNumber,
      values.contractId,
      values.qualityId,
      values.incoterm,
      values.purchaseType,
      contractType,
      values.pcrContractId,
    );
    if (
      contractType === ContractModesEnum.PURCHASE ||
      contractType === ContractModesEnum.PROCUREMENT_FEES
    ) {
      pcCommercialBargeData(req);
    } else if (
      contractType === ContractModesEnum.SALES ||
      contractType === ContractModesEnum.MARKETING_FEES
    ) {
      scCommercialBargeData(req);
    }
  };
  // for 113 line at update time
  // const bgDs = { contractNo: res.data.contractId, bgUId: bg.bgUId, bargeQuantityInMt: bg.bargeQuantityInMt, purchasingTerms: res.data.purchasingTerms, coalSupplierName: res.data.bpName, freightPricePmt: res.data.coalPricePMT, cmBgTransactionId: bg.cmBgTransactionId, incoterm: res.data.incoterm, qualityGroupId: bg.qualityGroupId, baseCurrency: res.data.baseCurrency }
  // bargeData.push(bgDs);

  const getAllCommercialData = (id: string) => {
    const req = new PerFormaReqIdDto(
      id,
      contractType,
      authContext.defaultPlant,
    );
    cmService
      .getAllCommercialCostingsData(req)
      .then((res) => {
        if (res.status) {
          res.data.businessNumber = res.data.bnId ? res.data.bnId : undefined;
          setInitialValues(res.data);
          const req: any = new CalculationFilterDataReq(
            authContext.defaultPlant,
            res.data.businessNumber,
            res.data.contractId,
            res.data.qualityId,
            res.data.incoterm,
            res.data.purchaseType,
            contractType,
            res.data.pcrContractId,
          );
          // purchase commercial
          if (contractType === ContractModesEnum.PURCHASE) {
            cmService
              .getBargesDataForCommercial(req)
              .then((response) => {
                if (res.status) {
                  const bargeId = [];
                  const bargesData = [];
                  for (const bg of res.data.transactionBarges) {
                    bargeId.push(bg.bgUId);
                    const bgD = response.data.filter(
                      (rec) => rec.bgUId === bg.bgUId,
                    )[0];
                    bargesData.push(bgD);
                  }
                  rowSelection.onChange(bargeId, bargesData);
                  formRef.setFieldValue(
                    'totalBargeQuantity',
                    Number(res.data.totalBargeQuantity),
                  );
                  setBargeData(response.data);
                  setTaxTotal((prev) => {
                    return {
                      ...prev,
                      totalCoalPrice:
                        Number(res.data?.totalBargeQuantity) *
                        (res.data.exchangeRateValue
                          ? Number(res.data.exchangeRateValue)
                          : 1),
                    };
                  });
                  // setPerformaAdvance(response.perFormaData);
                  setTotalBargeQuantity(Number(res.data.totalBargeQuantity));
                  qualityOnChange(Number(res.data.totalBargeQuantity));
                  // setPerformaAdvance(response.perFormaData);
                  setAdjustedData(res.data.advanceTarnsactionsData);
                  const advTotal = Number(
                    res.data.advanceTarnsactionsData
                      .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
                      .toFixed(2),
                  ).toLocaleString();
                  formRef.setFieldValue('totalAdjustedValue', advTotal);
                } else {
                  setBargeData([]);
                }
              })
              .catch((err) => {
                console.log(err.message);
                setBargeData([]);
              });
          } else if (contractType === ContractModesEnum.SALES) {
            // sales commercial
            cmService
              .getBargesDataForSalesCommercial(req)
              .then((response) => {
                if (res.status) {
                  const bargeId = [];
                  const bargesData = [];
                  for (const bg of res.data.transactionBarges) {
                    bargeId.push(bg.bgUId);
                    const bgD = response.data.filter(
                      (rec) => rec.bgUId === bg.bgUId,
                    )[0];
                    bargesData.push(bgD);
                  }
                  rowSelection.onChange(bargeId, bargesData);
                  setBargeData(response.data);
                  setTaxTotal((prev) => {
                    return {
                      ...prev,
                      totalCoalPrice:
                        Number(res.data?.totalBargeQuantity) *
                        (res.data.exchangeRateValue
                          ? Number(res.data.exchangeRateValue)
                          : 1),
                    };
                  });
                  formRef.setFieldValue(
                    'totalBargeQuantity',
                    Number(res.data.totalBargeQuantity).toFixed(3),
                  );
                  qualityOnChange(Number(res.data.totalBargeQuantity));
                  setTotalBargeQuantity(
                    Number(res.data.totalBargeQuantity.toFixed(3)),
                  );
                } else {
                  setBargeData([]);
                }
              })
              .catch((err) => {
                console.log(err.message);
                setBargeData([]);
              });
          }
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setInitialValues(null);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setInitialValues(null);
      });
  };

  const getInvoiceContracts = (req: AdvanceTypeReqDto, incoterm: string) => {
    if (req?.bargeId?.length) {
      cmService
        .getInvoiceContracts(req)
        .then((res) => {
          if (res.status) {
            setInvoiceContracts(res.data);
            if (incoterm === 'FOB BARGE') {
              setPerformaAdvance(res.data);
            }
          } else {
            // AlertMessages.getErrorMessage(res.internalMessage)
            setInvoiceContracts([]);
            setPerformaAdvance([]);
          }
        })
        .catch((err) => console.log(err.message));
    } else {
      formRef.setFieldValue('advanceType', undefined);
      formRef.setFieldValue('invoiceContractId', undefined);
      formRef.setFieldValue('advanceBalance', undefined);
      formRef.setFieldValue('advanceToAdjust', undefined);
    }
  };

  const rowSelection = {
    columnTitle: <></>,
    onChange: (selectedRowKeys: string[], selectedRows: any[]) => {
      setBargeSelection(selectedRows);
      setBargeSelectionRowKeys(selectedRowKeys);
      let sum: number = 0;
      for (const t of selectedRows) {
        sum += Number(t.bargeQuantityInMt);
      }
    //   const req = new AdvanceTypeReqDto(
    //     authContext.defaultPlant,
    //     AdvanceEnumType.Against_Performa_Invoice,
    //     formRef.getFieldValue('contractId'),
    //     selectedRowKeys,
    //     formRef.getFieldValue('purchaseType'),
    //     formRef.getFieldValue('qualityId'),
    //     bargeSelection[0]?.coalSupplierId,
    //   );
    //   getInvoiceContracts(req, selectedRows[0]?.incoterm);
      setTotalBargeQuantity(Number(sum.toFixed(3)));
    },
    getCheckboxProps: (record) => {
      if (paramsId || cmCostingId) {
        record.isBargeMapped = false;
      }
      if (bargeSelection[0]?.qualityGroupId || record.isBargeMapped) {
        if (
          bargeSelection[0]?.qualityGroupId !== record?.qualityGroupId ||
          record.isBargeMapped
        ) {
          return { disabled: true };
        }
      }

      return {};
    },
    selectedRowKeys: bargeSelectionRowKeys,
  };

  const submitHandler = () => {
    formRef.validateFields().then((values) => {
      const gcvAdjustment = formRef.getFieldValue('gcvAdjustment');
      const ashPriceAdjustment = formRef.getFieldValue('ashPriceAdjustment');
      const tsPriceAdjustment = formRef.getFieldValue('tsPriceAdjustment');
      const tsRejection = formRef.getFieldValue('tsRejection');
      const ashRejection = formRef.getFieldValue('ashRejection');
      const gcvRejection = formRef.getFieldValue('gcvRejection');
      const barges = [];
      for (const bg of bargeSelection) {
        barges.push({
          bargeId: bg.bgUId,
          coalPricePerMt: bg.freightPricePmt,
          baseCurrency: values.baseCurrency,
          quoteCurrency: authContext.defaultPlantCurrency,
          exchangeRate: values.exchangeRateValue,
          exchangeDate: values.exchangeDate,
          total: taxTotal.invoiceTotal,
          qualityGroupId: bg.qualityGroupId,
          plantCode: authContext.defaultPlant,
          userName: authContext.user.userName,
          cmBgTransactionId: initialValues ? bg.cmBgTransactionId : undefined,
          purchasingTerms: bg.purchasingTerms ? bg.purchasingTerms : '',
        });
      }
      cmService
        .saveCommercialCostings({
          ...values,
          gcvAdjustment,
          ashPriceAdjustment,
          tsPriceAdjustment,
          tsRejection,
          ashRejection,
          gcvRejection,
          ...taxTotal,
          totalAdjustedValue: getNumberFromLocalString(
            values?.totalAdjustedValue,
          ),
          costingInvoiceTotal: getNumberFromLocalString(
            values?.costingInvoiceTotal,
          ),
          remainingBalance: getNumberFromLocalString(
            values?.remainingBalance || 0,
          ),
          costingType: costingType,
          transactionBarges: barges,
          plantCode: authContext.defaultPlant,
          userName: authContext.user.userName,
          quoteCurrency: authContext.defaultPlantCurrency,
          coalPricePMT: bargeSelection[0]?.freightPricePmt,
          contractType: contractType,
          incoterm: bargeSelection[0]?.incoterm,
          costingNo: initialValues?.costingNo,
          adjustmentTransactions: adjustedData,
          vendorId: bargeSelection[0]?.coalSupplierId,
        })
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        });
    });
  };

  const qualityOnChange = (e: number) => {
    setQualityFieldValue(Number(e));
  };

  return (
    <>
      <Card className="card-radius">
              <Card
                key={cmCostingId}
                className="default-card-class"
                title={`${costingType} Costings ${
                  contractType === ContractModesEnum.PURCHASE
                    ? '- Purchase Contract'
                    : contractType === ContractModesEnum.SALES
                    ? '- Sales Contract'
                    : ''
                } `}
                // extra={
                //   <>
                //     {viewPermission && (
                //       <Button
                //         onClick={() => {
                //           setGridDrawer(true);
                //           setDummyRefresh(dummyRefresh + 1);
                //         }}
                //         className="panel_button"
                //       >
                //         View
                //       </Button>
                //     )}
                //   </>
                // }
              >
                <Row>
                  <Col span={24}>
                    {createPermission && (
                      <CoalCostingFilterComponent
                        key={cmCostingId}
                        formRef={formRef}
                        loadBargesOnClickHandler={loadBargesOnClickHandler}
                        isBusinessNumberMandatory={
                          contractType === ContractModesEnum.SALES
                        }
                        initialValues={initialValues}
                        contractType={contractType}
                        setBargeData={setBargeData}
                        pcrContractType={pcrContractType}
                      />
                    )}
                  </Col>
                </Row>

                <br></br>
                {bargeData.length !== 0 && (
                  <>
                    <Row>
                      <Col span={24}>
                        <CommercialCostingsBargeSelection
                          key={cmCostingId + dummyRefresh}
                          bargeGridData={bargeData}
                          rowSelection={rowSelection}
                        />
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                      <Col offset={20}>
                        <Descriptions
                          bordered
                          style={{ width: '200px' }}
                          size="small"
                        >
                          <Descriptions.Item label="Quantity">
                            <Form form={formRef} layout="vertical">
                              <Form.Item 
                                name={'totalBargeQuantity'}
                              >
                                <Input
                                  // type="number"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    qualityOnChange(Number(e.target.value))
                                  }
                                ></Input>
                              </Form.Item>
                            </Form>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={12}>
                        <Card
                          className="default-card-class"
                          title={'Price Adjustment'}
                          extra={
                            <>
                              <Button
                                className="panel_button"
                                disabled={
                                  bargeSelection[0]?.purchasingTerms ===
                                    ContractTermEnum.NON_SPEC ||
                                  bargeSelection[0]?.noPriceAdjustment ===
                                    'true'
                                }
                                onClick={() => {
                                  setPriceAdjustmentDrawer(true);
                                  setDummyRefresh((prev) => prev + 1);
                                }}
                              >
                                Quality Adjustment
                              </Button>
                            </>
                          }
                        >
                          <CommercialPriceAdjustment
                            key={cmCostingId}
                            contractType={contractType}
                            initialValues={initialValues}
                            taxTotal={taxTotal}
                            setTaxTotal={setTaxTotal}
                            totalBargeQuantity={totalBargeQuantity}
                            formRef={formRef}
                            bargeSelection={bargeSelection[0]}
                            qualityFieldValue={qualityFieldValue}
                            setPriceAdjustmentDrawer={setPriceAdjustmentDrawer}
                            priceAdjustmentDrawer={priceAdjustmentDrawer}
                            dummyRefresh={dummyRefresh}
                            setDummyRefresh={setDummyRefresh}
                            submitHandler={submitHandler}
                          />
                        </Card>
                      </Col>

                      <Col span={12}>
                        <Card
                          className="default-card-class"
                          title={'Advance Adjustment'}
                        >
                          <CmAdvanceAdjustments
                            key={cmCostingId}
                            bargeSelectionRowKeys={bargeSelectionRowKeys}
                            formRef={formRef}
                            vendorId={bargeSelection[0]?.coalSupplierId}
                            contractId={bargeSelection[0]?.contractId}
                            totalInvoice={Number(taxTotal?.invoiceTotal)}
                            performaAdvance={performaAdvance}
                            setAdjustedData={setAdjustedData}
                            adjustedData={adjustedData}
                            setInvoiceContracts={setInvoiceContracts}
                            invoiceContracts={invoiceContracts}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            
      </Card>
      <Drawer
        open={gridDrawer}
        onClose={() => setGridDrawer(false)}
        key={dummyRefresh}
        width={window.innerWidth > 768 ? '70%' : '85%'}
      >
        <SavedCostingsGrid
          setPerFormaId={setCmCostingId}
          contractType={contractType}
          costingType={costingType}
          referenceFeatures={referenceFeatures}
          setGridDrawer={setGridDrawer}
          accessId={accessId}
        />
      </Drawer>
    </>
  );
};

export default CmCoalCostingMainForm;
