import {
  DeleteOutlined,
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
  MinusSquareTwoTone,
} from '@ant-design/icons';
import {
  BargeCostingVouchersParentDto,
  BargeService,
  BNoRequest,
  BusinessNoDropDownDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  ContractStatusEnum,
  ContractTypeDto,
  ExpenseEntryIdReq,
  ExpenseEntryService,
  NoteTypeReq,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import Invoice from '../../invoice/invoice';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import TabPane from 'antd/es/tabs/TabPane';
import PaymentNoticeApproval, {
  PaymentNoticeApprovalScreen,
} from './payment-notice-expensive-approval';
import { useNavigate } from 'react-router-dom';
import { PaymentNoticePageEnum } from '../../payment-notice';
import debounce from 'lodash/debounce';
import moment from 'moment';
export interface ExpenseEntryRejectedListProps {
  setEditDataToForm?: (values) => void;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;

  updateExpensive?: any;
  type: string;
  accessId?: any;
}

export default function ExpenseEntryRejectedList(
  props: ExpenseEntryRejectedListProps,
) {
  const { setDummyRefresh, updateExpensive, accessId } = props;
  const [rejectedEntries, setRejectedEntries] = useState<any[]>([]);
  const { authContext } = useAuthState();
  const [vouchers, setVouchers] = useState<BargeCostingVouchersParentDto>();
  const [searchedText, setSearchedText] = useState('');
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [businessDropDown, setBusinessDropDown] =
    useState<BusinessNoDropDownDto[]>();
  const [bargeDropDown, setBargeDropDown] = useState<any[]>();
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [selectedBarge, setSelectedBarge] = useState(null);
  const [expenseLedger, setExpenseLedgers] = useState<any>([]);
  const [selectedExpenseLedger, setSelectedExpenseLedger] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>('costing-creation');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  const [dateRangeType, setDateRangeType] = useState<string | null>(null);
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: null,
    to: null,
  });

  const navigate = useNavigate();

  const businessService = new BusinessNumberService();
  const bargeService = new BargeService();
  const service = new ExpenseEntryService();

  useEffect(() => {
    setActiveTab('costing-creation')
    getRejectedEntries();
    getExpenseLedgers();
    getBusinessNoDropDown();
    getBargeDropDown();
    setSearchedText(null);
    setSelectedExpenseLedger(null);
    setSelectedBusinessNo(null);
    setSelectedBarge(null);
    setDateRangeType(null);
  }, [props.type]);

  const deleteEntry = (value) => {
    const req = new ExpenseEntryIdReq();
    req.expenseEntryId = value;

    service
      .deleteEntry(req)
      .then((res) => {
        if (res.status) {
          setDummyRefresh((prev) => prev + 1);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage('');
      });
  };

  const getRejectedEntries = useCallback(
    (
      page = 1,
      pageSize = 25,
      search?: string,
      expenseLedger?: string,
      businessNo?: string,
      bargeId?: string,
      fromDate?: moment.Moment,
      toDate?: moment.Moment,
    ) => {
      const req = new NoteTypeReq(
        props.type === 'Account Receivable' ? 'Other Income' : '',
        authContext.defaultPlant,
        pageSize,
        (page - 1) * pageSize,
        search,
        expenseLedger,
        businessNo,
        bargeId,
        fromDate?.format('YYYY-MM-DD'),
        toDate?.clone().add(1, 'day').format('YYYY-MM-DD'),
      );
      const service = new ExpenseEntryService();
      service
        .getRejectedEntries(req)
        .then((res) => {
          if (res.status) {
            const filteredRejectedEntries = res.data.filter((item) => {
              return hasPermission(item);
            });
            setRejectedEntries(filteredRejectedEntries);
            setPagination((prev) => ({
              ...prev,
              current: page,
              pageSize: pageSize,
              total: res.totalCount || 0,
            }));
          } else {
            setRejectedEntries([]);
            console.log(res.internalMessage);
          }
        })
        .catch((err) => {
          setRejectedEntries([]);
          console.log(err.message);
        });
    },
    [authContext.defaultPlant, props.type],
  );

  const hasPermission = (ledger) => {
    let permission;
    if (ledger.act_led_name === 'Chemical Spray') {
      permission = 417;
      ledger.addInvoicePermission = {
        create: 425,
        view: 426,
        update: 427,
        delete: 428,
        approve: 429,
        reject: 430,
        release: 431,
        files: 432,
      };
      ledger.editPermission = 418;
      ledger.deletePermission = 419;
    } else if (ledger.act_led_name === 'Export Tax') {
      permission = 438;
      ledger.addInvoicePermission = {
        create: 446,
        view: 447,
        update: 448,
        delete: 449,
        approve: 450,
        reject: 451,
        release: 452,
        files: 453,
      };
      ledger.editPermission = 439;
      ledger.deletePermission = 440;
    } else if (ledger.act_led_name === 'Documentation Charges') {
      permission = 459;
      ledger.addInvoicePermission = {
        create: 467,
        view: 468,
        update: 469,
        delete: 470,
        approve: 471,
        reject: 472,
        release: 473,
        files: 474,
      };
      ledger.editPermission = 460;
      ledger.deletePermission = 461;
    } else if (ledger.act_led_name === 'Laporan Charges') {
      permission = 480;
      ledger.addInvoicePermission = {
        create: 488,
        view: 489,
        update: 490,
        delete: 491,
        approve: 492,
        reject: 493,
        release: 494,
        files: 495,
      };
      ledger.editPermission = 481;
      ledger.deletePermission = 482;
    } else if (ledger.act_led_name === 'Sampling & Analysis Charges') {
      permission = 501;
      ledger.addInvoicePermission = {
        create: 509,
        view: 510,
        update: 511,
        delete: 512,
        approve: 513,
        reject: 514,
        release: 415,
        files: 416,
      };
      ledger.editPermission = 502;
      ledger.deletePermission = 503;
    } else if (ledger.act_led_name === 'Pre Shipment Inspection') {
      permission = 522;
      ledger.addInvoicePermission = {
        create: 530,
        view: 531,
        update: 532,
        delete: 533,
        approve: 534,
        reject: 535,
        release: 536,
        files: 437,
      };
      ledger.editPermission = 523;
      ledger.deletePermission = 524;
    } else if (ledger.act_led_name === 'Other Expenses') {
      permission = 543;
      ledger.addInvoicePermission = {
        create: 551,
        view: 552,
        update: 553,
        delete: 554,
        approve: 555,
        reject: 556,
        release: 557,
        files: 558,
      };
      ledger.editPermission = 544;
      ledger.deletePermission = 545;
    } else if (ledger.act_led_name === 'Return Cargo Charges') {
      permission = 564;
      ledger.addInvoicePermission = {
        create: 572,
        view: 573,
        update: 574,
        delete: 575,
        approve: 576,
        reject: 577,
        release: 578,
        files: 579,
      };
      ledger.editPermission = 565;
      ledger.deletePermission = 566;
    } else if (ledger.act_led_name === 'Floating Crane Charges') {
      permission = 585;
      ledger.addInvoicePermission = {
        create: 593,
        view: 594,
        update: 595,
        delete: 596,
        approve: 597,
        reject: 598,
        release: 599,
        files: 600,
      };
      ledger.editPermission = 586;
      ledger.deletePermission = 587;
    } else if (ledger.act_led_name === 'Stevedore Costing') {
      permission = 606;
      ledger.addInvoicePermission = {
        create: 614,
        view: 615,
        update: 616,
        delete: 617,
        approve: 618,
        reject: 619,
        release: 620,
        files: 621,
      };
      ledger.editPermission = 607;
      ledger.deletePermission = 608;
    } else if (ledger.act_led_name === 'Insurance') {
      permission = 687;
      ledger.addInvoicePermission = {
        create: 694,
        view: 695,
        update: 696,
        delete: 697,
        approve: 698,
        reject: 699,
        release: 700,
        files: 701,
      };
      ledger.editPermission = 688;
      ledger.deletePermission = 689;
    } else if (ledger.act_led_name === 'Freight Costing') {
      permission = 627;
      ledger.addInvoicePermission = {
        create: 634,
        view: 635,
        update: 636,
        delete: 637,
        approve: 638,
        reject: 639,
        release: 640,
        files: 641,
      };
      ledger.editPermission = 628;
      ledger.deletePermission = 629;
    } else if (ledger.act_led_name === 'PNBP Charges') {
      permission = 648;
      ledger.addInvoicePermission = {
        create: 654,
        view: 655,
        update: 656,
        delete: 657,
        approve: 658,
        reject: 659,
        release: 660,
        files: 661,
      };
      ledger.editPermission = 648;
      ledger.deletePermission = 649;
    } else if (ledger.act_led_name === 'Other Income') {
      permission = 667;
      ledger.addInvoicePermission = {
        create: 674,
        view: 675,
        update: 676,
        delete: 677,
        approve: 678,
        reject: 679,
        release: 680,
        files: 681,
      };
      ledger.editPermission = 668;
      ledger.deletePermission = 669;
    } else {
      permission = 1;
      ledger.addInvoicePermission = {
        create: 1,
        view: 1,
        update: 1,
        delete: 1,
        approve: 1,
        reject: 1,
        release: 1,
        files: 1,
      };
      ledger.editPermission = 1;
      ledger.deletePermission = 1;
    }
    if (isPermissionExist([permission])) {
      return ledger;
    }
  };

  const hasDropDownPermission = (ledger) => {
    let permission;
    if (ledger.expenseLedger === 'Chemical Spray') {
      permission = 416;
    } else if (ledger.expenseLedger === 'Export Tax') {
      permission = 437;
    } else if (ledger.expenseLedger === 'Documentation Charges') {
      permission = 458;
    } else if (ledger.expenseLedger === 'Laporan Charges') {
      permission = 479;
    } else if (ledger.expenseLedger === 'Sampling & Analysis Charges') {
      permission = 500;
    } else if (ledger.expenseLedger === 'Pre Shipment Inspection') {
      permission = 521;
    } else if (ledger.expenseLedger === 'Other Expenses') {
      permission = 542;
    } else if (ledger.expenseLedger === 'Return Cargo Charges') {
      permission = 563;
    } else if (ledger.expenseLedger === 'Floating Crane Charges') {
      permission = 584;
    } else if (ledger.expenseLedger === 'Stevedore Costing') {
      permission = 605;
    } else if (ledger.expenseLedger === 'Insurance') {
      permission = 686;
    } else if (ledger.expenseLedger === 'Freight Costing') {
      permission = 626;
    } else if (ledger.expenseLedger === 'PNBP Charges') {
      permission = 646;
    } else if (ledger.expenseLedger === 'Other Income') {
      permission = 666;
    } else {
      permission = 1;
    }
    if (isPermissionExist([permission])) {
      return ledger;
    }
  };

  const getExpensiveInvoiceData = (id: string) => {
    const req = new ExpenseEntryIdReq();
    req.expenseEntryId = id;
    req.plantCode = authContext.defaultPlant;
    service
      .getExpensiveInvoiceData(req)
      .then((res) => {
        if (res.status) {
          setVouchers(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getExpenseLedgers = () => {
    service
      .getExpenseLedgers()
      .then((res) => {
        if (res.status) {
          const permittedLedgers = res.data?.filter((ledger) => {
            return hasDropDownPermission(ledger);
          });
          setExpenseLedgers(permittedLedgers);
          // setExpenseLedgers(res.data);
        } else {
          setExpenseLedgers([]);
        }
      })
      .catch((err) => console.log(err.message, 'err message'));
  };

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessService
      .getBusinessNoDropDown(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBargeDropDown = () => {
    const req = new BNoRequest('');
    req.plantCode = authContext.defaultPlant;
    bargeService
      .getBargeDropForBusinessNo(req)
      .then((res) => {
        if (res.status) {
          let barge = res.data?.map((item) => {
            let bargeNo =
              SequenceUtils.formatNumberToSpecificLength(
                item.bargeId.toString(),
              ) +
              ' ' +
              `(${item.bargeNomination})`;
            return {
              id: item.id,
              bargeNo,
            };
          });
          setBargeDropDown(barge);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleExpenseLedgerChange = (value: string) => {
    setSelectedExpenseLedger(value);
    getRejectedEntries(
      1,
      pagination.pageSize,
      searchedText,
      value,
      selectedBusinessNo,
      dateRangeValues.from,
      dateRangeValues.to,
    );
  };

  const handleBusinessNoChange = (value: string) => {
    setSelectedBusinessNo(value);
    getRejectedEntries(
      1,
      pagination.pageSize,
      searchedText,
      selectedExpenseLedger,
      value,
      selectedBarge,
      dateRangeValues.from,
      dateRangeValues.to,
    );
  };

  const handleBargeChange = (value: string) => {
    setSelectedBarge(value);
    getRejectedEntries(
      1,
      pagination.pageSize,
      searchedText,
      selectedExpenseLedger,
      selectedBusinessNo,
      value,
      dateRangeValues.from,
      dateRangeValues.to,
    );
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string, page: number, pageSize: number) => {
        getRejectedEntries(
          page,
          pageSize,
          value,
          selectedExpenseLedger,
          selectedBusinessNo,
          selectedBarge,
          dateRangeValues.from,
          dateRangeValues.to,
        );
      }, 500),
    [
      getRejectedEntries,
      selectedExpenseLedger,
      selectedBusinessNo,
      selectedBarge,
      dateRangeValues,
    ],
  );

  const dateRangeDropDown = [
    { value: 'this_month', label: 'This Month' },
    { value: 'previous_month', label: 'Previous Month' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'previous_quarter', label: 'Previous Quarter' },
    { value: 'this_year', label: 'This Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Date Range' },
  ];

  const handleDateRangeTypeChange = (value: string | null) => {
    setDateRangeType(value);

    if (value === 'custom') {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    if (!value) {
      setDateRangeValues({ from: null, to: null });
      // Call API when clearing the date range selection
      getRejectedEntries(
        1,
        pagination.pageSize,
        searchedText,
        selectedExpenseLedger,
        selectedBusinessNo,
        selectedBarge,
        null,
        null,
      );
      return;
    }

    const now = moment();
    let fromDate: moment.Moment;
    let toDate: moment.Moment;

    switch (value) {
      case 'this_month':
        fromDate = now.clone().startOf('month');
        toDate = now.clone().endOf('month');
        break;
      case 'previous_month':
        fromDate = now.clone().subtract(1, 'month').startOf('month');
        toDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'this_quarter':
        fromDate = now.clone().startOf('quarter');
        toDate = now.clone().endOf('quarter');
        break;
      case 'previous_quarter':
        fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
        toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'this_year':
        fromDate = now.clone().startOf('year');
        toDate = now.clone().endOf('year');
        break;
      case 'previous_year':
        fromDate = now.clone().subtract(1, 'year').startOf('year');
        toDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      default:
        return;
    }

    setDateRangeValues({ from: fromDate, to: toDate });
    getRejectedEntries(
      1,
      pagination.pageSize,
      searchedText,
      selectedExpenseLedger,
      selectedBusinessNo,
      selectedBarge,
      fromDate,
      toDate,
    );
  };

  const handleDateRangeValuesChange = (
    dates: [any | null, any | null] | null,
  ) => {
    if (!dates || !dates[0] || !dates[1]) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const [from, to] = dates;
    setDateRangeValues({ from, to });

    getRejectedEntries(
      1,
      pagination.pageSize,
      searchedText,
      selectedExpenseLedger,
      selectedBusinessNo,
      selectedBarge,
      from,
      to,
    );
  };

  const columns: any = [
    // {
    //     title: 'Actin',
    //     dataIndex: 'isInPmtNotice',
    //     width: '15%',
    //     align: 'center',
    //     key: 'expenseEntryId',
    //     onCell: (record: any) => ({
    //         rowSpan: record.rowSpan
    //     }),
    //     render: (text, rowData) => (
    //         <span>
    //           {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([rowData?.editPermission]) && (

    //             <Tooltip placement="topRight" title="Edit">
    //                 <EditOutlined
    //                     type="edit"
    //                     onClick={() => updateExpensive(rowData.expenseEntryId)}
    //                     style={{ color: '#1890ff', fontSize: '18px' }}
    //                 /></Tooltip>)}

    //             <Divider type="vertical" />
    //             {isPermissionExist([rowData?.deletePermission]) && (
    //             <Popconfirm
    //                 disabled={text}
    //                 onConfirm={e => {
    //                     deleteEntry(rowData.expenseEntryId)
    //                 }}
    //                 title={'Are you sure to delete ?'}>
    //                 <Tooltip placement="topRight" title={`${text === true ? 'You Cant Delete This Record' : 'Delete'}`}>
    //                     <DeleteOutlined type='delete' style={{ color: `${text === true ? '#d1b2b2' : 'red'}`, fontSize: '18px' }} />
    //                 </Tooltip>
    //             </Popconfirm>)}
    //             <Divider type="vertical" />
    //             {isPermissionExist([rowData?.addInvoicePermission?.view]) && (
    //             <Tooltip placement="topRight" title="Add Invoice">
    //                 <PlusSquareTwoTone onClick={() => {
    //                     setDrawerOpen(true);
    //                     setCostingId((prev) => { return { ...prev, costingId: rowData.expenseEntryId, totalAmount: rowData.totalAmount, accessId: rowData.addInvoicePermission } });
    //                     // setDummyRefresh(prev => prev + 1);
    //                 }}
    //                     style={{ color: '#1890ff', fontSize: '20px' }} />
    //             </Tooltip>)}
    //              <Tooltip placement="topRight" title="Add Invoice">
    //                 {!invoiceFrom ? <>
    //                     <PlusSquareTwoTone
    //                         key={rowData.expenseEntryId}
    //                         onClick={() => {
    //                             setInvoiceForm(true);
    //                             setInvoiceCostingData((prev) => { return { ...prev, referenceFeatures: ReferenceFeatures.EXPENSE_ENTRY, costingId: rowData.expenseEntryId, totalAmount: Number(rowData.totalAmount) } })

    //                         }}
    //                         style={{ color: '#1890ff', fontSize: '20px' }} />

    //                 </> : <>

    //                     <MinusSquareTwoTone key={rowData.expenseEntryId} style={{ color: '#1890ff', fontSize: '20px' }} onClick={() => {
    //                         setInvoiceForm(false);
    //                     }} />
    //                 </>

    //                 }

    //             </Tooltip>

    //             <Divider type='vertical'></Divider>

    //             <PDFDownloadLink
    //                 document={<Invoice invoice={vouchers} demurrageName={''} costingName={vouchers?.costingName} />}
    //                 fileName={`${rowData?.expenseEntryNo}.pdf`}
    //             >
    //                 <Tooltip placement="topRight" title="Invoice">
    //                     <FilePdfTwoTone onClick={() => getExpensiveInvoiceData(rowData.expenseEntryId)} style={{ color: '#1890ff', fontSize: '20px' }}
    //                     />
    //                 </Tooltip>
    //             </PDFDownloadLink>

    //         </span>
    //     )
    // },
    {
      title: 'Entry Ref#',
      dataIndex: 'expenseEntryNo',
      // sorter: (a, b) => a.expenseEntryNo.localeCompare(b.expenseEntryNo),
      // sortDirections: ['descend', 'ascend'],
      // filteredValue: [String(searchedText).toLowerCase()],
      // onFilter: (value, record) => {
      //   return SequenceUtils.globalFilter(value, record);
      // },
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, record) => {
        let path;
        if (props.type === 'Account Payable') {
          path = 'expense-entry-detail-view';
        } else {
          path = 'other-income-expense-entry-detail-view';
        }
        return (
          <>
            <a
              className="link"
              href={`/#/${path}?costing_id=${record.expenseEntryId}`}
            >
              {record.expenseEntryNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'Expense Ledger',
      dataIndex: 'actLedName',
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
    },
    {
      title: 'Expense Type',
      dataIndex: 'expensiveType',
      // sorter: (a, b) => a.expensiveType.localeCompare(b.expensiveType),
      // sortDirections: ['descend', 'ascend'],
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
    },
    {
      title: 'BusinessNo',
      dataIndex: 'expenseEntryBusinessNos',
      // sorter: (a, b) => a.businessNumber.localeCompare(b.businessNumber),
      // sortDirections: ['descend', 'ascend'],
      // render: (value, record) => {
      //   const link = `/#/bn-detail-view?bn_Id=${record.bnId}`;
      //   return (
      //     <>
      //       <a href={link} className="link">
      //         {value}
      //       </a>
      //     </>
      //   );
      // },
      render: (expenseEntryBusinessNos) => {
        return (
          <>
            {expenseEntryBusinessNos?.map((business, index) => {
              const link = `/#/bn-detail-view?bn_Id=${business.bnId}`;
              return (
                <>
                  {business.bnId === null || business.bnId === undefined ? (
                    '(null)'
                  ) : (
                    <div key={business.bnId}>
                      <a href={link} className="link" target="_blank">
                        {business.businessNo} {`(${business.mvName})`}
                      </a>
                      {index !== expenseEntryBusinessNos.length - 1 && (
                        <Divider />
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Barges',
      dataIndex: 'expenseEntryBarges',
      // sorter: (a, b) => a.bargeSupplier.localeCompare(b.bargeSupplier),
      // sortDirections: ['descend', 'ascend'],
      // render: (v, record) => {
      //   const link = `/#/barge-detail-view?barge_id=${record.bgId}`;
      //   return (
      //     <>
      //       {v === undefined || v === null ? (
      //         '(null)'
      //       ) : (
      //         <a href={link} className="link">
      //           {SequenceUtils.formatNumberToSpecificLength(v) +
      //             ' ' +
      //             `(${record.bargeName})`}
      //         </a>
      //       )}
      //     </>
      //   );
      // },
      render: (expenseEntryBarges) => {
        return (
          <>
            {expenseEntryBarges?.map((barge, index) => {
              const link = `/#/barge-detail-view?barge_id=${barge.bargeId}`;
              return (
                <>
                  {barge.bargeId === undefined || barge.bargeId === null ? (
                    '(null)'
                  ) : (
                    <div key={barge.bargeId}>
                      <a href={link} className="link" target="_blank">
                        {SequenceUtils.formatNumberToSpecificLength(
                          barge.bargeNo,
                        ) +
                          ' ' +
                          `(${barge.bargeNomination})`}
                      </a>
                      {index !== expenseEntryBarges.length - 1 && <Divider />}
                    </div>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      // sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
      // sortDirections: ['descend', 'ascend'],
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },

    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, _) => {
        return (
          <>
            <StatusTag status={value}></StatusTag>
          </>
        );
      },
    },
  ];

  const entriesDataModification = (data: any) => {
    const entriesData = [];
    data.forEach((parent) => {
      parent.transactionalData.forEach((child, index) => {
        entriesData.push({
          expenseEntryNo: parent.expenseEntryNo,
          expensiveType: parent.expensiveType,
          totalAmount: parent.totalAmount,
          // rowSpan: index === 0 ? parent.transactionalData.length : 0,
          businessNumber: child.businessNumber + `(${child.motherVesselName})`,
          bargeId: child.bargeId,
          approvalStatus: parent.approvalStatus,
          bnId: child.bnId,
          bgId: child.bgId,
          expenseEntryId: parent.expenseEntryId,
          bargeName: child.bargeName,
          isInPmtNotice: parent.isInPmtNotice,
          act_led_name: parent.act_led_name,
          addInvoicePermission: parent.addInvoicePermission,
          editPermission: parent.editPermission,
          deletePermission: parent.deletePermission,
        });
      });
    });

    return entriesData;
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            ...(isPermissionExist([
              417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667,
              687,
            ])
              ? [
                  {
                    key: 'costing-creation',
                    label: 'Costing Creation',
                    children: (
                      <>
                        <Row
                          justify="space-between"
                          className="mb-12 mt-8"
                          gutter={[0, 8]}
                        >
                          <Col xs={24} md={20}>
                            <Flex gap={10} wrap="wrap">
                              <Input.Search
                                className="input-search"
                                style={{ width: 225 }}
                                placeholder="Search"
                                allowClear
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSearchedText(value);
                                  debouncedSearch(
                                    value,
                                    1,
                                    pagination.pageSize,
                                  );
                                }}
                                onSearch={(value) => {
                                  setSearchedText(value);
                                  // Immediate search on enter key or search button click
                                  getRejectedEntries(
                                    pagination.current,
                                    pagination.pageSize,
                                    value,
                                    selectedExpenseLedger,
                                    selectedBusinessNo,
                                    selectedBarge,
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  );
                                }}
                              />
                              {props.type !== 'Account Receivable' && (
                                <Select
                                  style={{ width: 175 }}
                                  placeholder="Expense Ledger"
                                  value={selectedExpenseLedger}
                                  onChange={handleExpenseLedgerChange}
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option!.children as unknown as string)
                                      .toString()
                                      .toLocaleLowerCase()
                                      .includes(input.toLocaleLowerCase())
                                  }
                                >
                                  {expenseLedger
                                    ?.filter((led) =>
                                      props.type === 'Account Payable'
                                        ? led.expenseLedger !== 'Other Income'
                                        : led.expenseLedger === 'Other Income',
                                    )
                                    .map((el) => {
                                      return (
                                        <Select.Option
                                          // bpCodes={el.categories}
                                          // el={el.expenseLedger}
                                          value={el.actLedId}
                                          key={el.actLedId + 2}
                                        >
                                          {el.expenseLedger}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              )}
                              <Select
                                style={{ width: 175 }}
                                placeholder="Business"
                                value={selectedBusinessNo}
                                onChange={handleBusinessNoChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                showSearch
                                allowClear
                              >
                                {businessDropDown?.map((item, index) => (
                                  <Select.Option
                                    value={item.bnId}
                                    key={item.bnId + '-' + index}
                                  >
                                    {item.businessName}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Select
                                style={{ width: 175 }}
                                placeholder="Barge"
                                value={selectedBarge}
                                onChange={handleBargeChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                showSearch
                                allowClear
                              >
                                {bargeDropDown?.map((item, index) => (
                                  <Select.Option
                                    value={item.id}
                                    key={item.id + '-' + index}
                                  >
                                    {item.bargeNo}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Select
                                placeholder="Date Range"
                                style={{ width: 175 }}
                                allowClear
                                value={dateRangeType}
                                onChange={handleDateRangeTypeChange}
                              >
                                {dateRangeDropDown.map((item, index) => (
                                  <Select.Option
                                    value={item.value}
                                    key={item.value + '-' + index}
                                  >
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                              {dateRangeType === 'custom' && (
                                <DatePicker.RangePicker
                                  style={{ maxWidth: 175 }}
                                  value={[
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  ]}
                                  onChange={handleDateRangeValuesChange}
                                />
                              )}
                            </Flex>
                          </Col>
                          <Col>
                            <Button
                              className="new-button"
                              onClick={() =>
                                navigate(
                                  props.type === 'Account Receivable'
                                    ? '/create-other-income-expense-entry'
                                    : '/create-expense-entry',
                                )
                              }
                            >
                              New Expense Entry
                            </Button>
                          </Col>
                        </Row>
                        <Table
                          className="contracts-list"
                          columns={columns}
                          dataSource={rejectedEntries}
                          rowKey={(row) => row.expenseEntryId}
                          pagination={{
                            ...pagination,
                            showTotal: (total) => (
                              <span className="page-total-items">
                                Total {total} items
                              </span>
                            ),
                            showSizeChanger: true,
                            pageSizeOptions: ['25', '50', '100'],
                            onChange: (page, pageSize) => {
                              getRejectedEntries(
                                page,
                                pageSize,
                                searchedText,
                                selectedExpenseLedger,
                                selectedBusinessNo,
                                selectedBarge,
                                dateRangeValues.from,
                                dateRangeValues.to,
                              );
                            },
                            style: {
                              paddingTop: '12px',
                            },
                          }}
                        />
                      </>
                    ),
                  },
                ]
              : []),
            ...(isPermissionExist([
              421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670,
              690,
            ])
              ? [
                  {
                    key: 'business-approval',
                    label: 'Business Approval',
                    children: (
                      <PaymentNoticeApproval
                        type={PaymentNoticeApprovalScreen.BUSINESS_APPROVAL}
                        paymentType={
                          props.type === 'Account Receivable'
                            ? PaymentNoticePageEnum.RECEIVABLES
                            : undefined
                        }
                        key={`${props.type} - expense-entry-approval`}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Card>
      <AddInvoiceDrawer
        openDrawer={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        costingId={costingId}
        setDummyRefresh={setDummyRefresh}
        referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY}
      />
      {/* {invoiceFrom &&

                <AddInvoice key={inVoiceCostingData.expenseEntryId} costingData={inVoiceCostingData} />
            } */}
    </>
  );
}
