import React from "react";
import { AdvancePreview, AdvanceReceiversService, ContractStatusEnum, ReferenceFeatures, VendorAdvService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Flex, Form, Input, List, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';
import { RightCircleOutlined, DownCircleOutlined } from "@ant-design/icons"
import { InvoiceDetails } from "../../../../costings/costing-detail-view";
import { useIntl } from 'react-intl';
import { getLocalFormat, internationalFormattedValue } from "@exportx/ui-utils";

import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface AdvanceApprovalProps {
  feature?: ReferenceFeatures;

}

export const AdvanceBusinessApprovals = (props: AdvanceApprovalProps) => {
  const { feature } = props
  const [advanceActive, setadvanceActive] = useState<any>();
  let pathToreDirect = feature === ReferenceFeatures.ADVANCE_RECEIVABLES ? '/advance-receivables-detailed-view' : '/advanceDetailView'
  const { authContext } = useAuthState()
  const service = new VendorAdvService()
  const advReceivableService = new AdvanceReceiversService()
  const [searchData, setSearchData] = useState("")
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [formRef] = Form.useForm();
  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    if (feature === ReferenceFeatures.ADVANCE_INVOICE) {
      getAdvanceApprovalCostings();
    } else getAdvanceReceivableApprovalCostings()
  }, [feature]);


  const getAdvanceReceivableApprovalCostings = () => {
    advReceivableService.getAdvanceReceivableApprovalCostings({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setadvanceActive(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }
  const getAdvanceApprovalCostings = () => {
    service.getAdvanceApprovalCostings({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setadvanceActive(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const advanceApproveOrReject = (costingId: string, status: string) => {
    const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status);
    if (feature === ReferenceFeatures.ADVANCE_INVOICE) {
      service.advanceApproveOrReject(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          window.location.reload()
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    else {
      console.log('req', req)
      advReceivableService.advanceReceivableApproveOrReject(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          window.location.reload()
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })


    }


  }


  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contract_no',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contract_no)
            .toLowerCase()
            .includes(value.toLocaleString())
        )
      },
      render: (value, rowData) => {
        const link = `/#${pathToreDirect}?id=${rowData.id}`
        return <> {
          <a href={link} className="link" >{value}</a>
        }
        </>
      }

    },

    {
      title: 'Contract Type',
      dataIndex: 'contract_type',
    },

    {
      title: 'Contract Date',
      dataIndex: 'contract_date',
      render: (text, record) => { return record.contract_date !== undefined ? moment(record.contract_date).format('DD\u2011\MMM\u2011') : "" },
      sorter: (a, b) => moment(a.contract_date).unix() - moment(b.contract_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bp_name',
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      render: (value, record) => {
        return <>
        <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
        </>
      }
    },

  ]

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.id);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }
  const renderItems = (record: any, index, indent, expanded) => {
    console.log('record', record)
    return <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }} >

      <Card
        bordered={false}
        className="card-shadow mb-2"
        styles={{ body: { padding: '0px 10px 15px' } }}
      >
        <Form
          form={formRef}
          // initialValues={initialValues}
          layout='vertical'
          autoComplete='off'
        >
          <Row className='pt-2'>
            <Form.Item style={{ width: '100%' }} name='remarks' label="Remarks"
              rules={[
                {
                  required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                }
              ]}>
              <Input.TextArea placeholder="Notes" />
            </Form.Item>
          </Row>
          <Row>
            <Col span={16}>
              <Row>
                <Col span={3}>Worklog :</Col>
                <Col span={20}>
                  {record?.workLog?.length > 0 && <List
                    bordered
                    dataSource={record?.workLog ? record?.workLog : []}
                    renderItem={(item, index) => (
                      <List.Item key={index} style={{ width: "100%" }}>
                        <div><span>{index + 1}</span><span>.</span>&nbsp;{item.workLog}</div>
                      </List.Item>
                    )}
                  />}

                </Col>
              </Row>

            </Col>
          </Row>


          <Flex align="middle" justify="flex-end">
            <Space size={10}>
              {record?.contract_status === ContractStatusEnum.PENDING_APPROVAL &&
                <Button size="small" className="approve" onClick={() => advanceApproveOrReject(record?.id, ContractStatusEnum.ACTIVE)} > <FontAwesomeIcon icon={faCheckCircle} /> Approve</Button>
              }
              <Popconfirm
                onConfirm={e => {
                  advanceApproveOrReject(record?.id, ContractStatusEnum.CANCELLED);
                }}
                title={'Are you sure to reject'}>
                <Tooltip placement="top" title={'Reject'}>
                  <Button size="small" className="reject"> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                </Tooltip>
              </Popconfirm>


            </Space>
          </Flex>
        </Form>
      </Card>

      <InvoiceDetails
        costingId={record.id}
        status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
        level={ContractStatusEnum.BUSINESS_APPROVAL}
        invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
        invApproveDisabled={record.contract_status === ContractStatusEnum.PENDING_APPROVAL}
      />
    </div>
  };

  return (
    <Card
      className='contracts-list card-radius'
      title={<Row className='card-title-buttons'>
        <Input.Search placeholder="Search Orders" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} className='input-search' />

      </Row>}
    >
      <Table
        className='contracts-list'
        columns={tableColumns}
        dataSource={advanceActive}
        bordered={false}
        rowKey={(rowData) => rowData.id}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right'
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined
              onClick={(e) => onExpand(record, e)}
            >
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
          )
        }
      />

    </Card>


    // <Form layout="vertical">
    //   <Row>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
    //     </Col>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

    //       <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
    //     </Col>
    //   </Row>

    // </Form>


    // </Card >
  )

}