import { ContractStatusEnum, DebCreIdReq, DebitCreditService, ReferenceFeatures } from "@exportx/shared-models-and-services";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Descriptions, Flex, Layout, Row, Space, Table, Tooltip, Typography } from "antd";
import { isPermissionExist, StatusTag, useAuthState } from "../../../../common";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CostingInvoice } from "../../../costings";
import { EditFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import CountUp from "react-countup";
import { color } from "html2canvas/dist/types/css/types/color";

interface IProps {
  referenceFeatures: ReferenceFeatures;
}

const { Text } = Typography;

export const DebitCreditNoteDetailView = (props: IProps) => {
  const { referenceFeatures } = props;
  const { authContext } = useAuthState();
  const location = useLocation();
  const navigate = useNavigate()
  const [data, setData] = useState<any>();
  const service = new DebitCreditService();
  const paramBargeId: any = queryString.parse(location.search)?.costing_id
  let { costingId } = paramBargeId != null ? { costingId: paramBargeId } : { costingId: null };

  useEffect(()=>{
    if(!isPermissionExist([747])){
      return navigate('/')
    }
  }, [])
  
  useEffect(() => {
    getDebitCreditData()
  },[referenceFeatures])

  let path;
  let pathToList;
  if(referenceFeatures === ReferenceFeatures.DEBIT_NOTE){
    path = 'create-debit-note-entry'
    pathToList = 'debit-note-entry'
  } else {
    path = 'create-credit-note-entry'
    pathToList = 'credit-note-entry'
  }

  const getDebitCreditData = () => {
    const req = new DebCreIdReq(costingId, authContext.defaultPlant, props.referenceFeatures)
    service.getDataForUpdateDebit(req).then(res => {
    // service.getDataForUpdate(req).then(res => {
        if (res.status) {
          setData(res.data);
        }
    }).catch(err => {
        console.log(err)
        // setBargeCostingData([]);
    })
  }

    const editHandler = () => {
      navigate(`/${path}?costingId=${costingId}`);
    }

    const subTotal = data?.debitCreditTransactions.reduce(
      (total, item) => total + Number(item.amount), 0);

    const footer = () => (
      <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
              <div style={{marginRight: '35px'}}>
                  <div><b>Sub Total:</b></div>
              </div>
              <div style={{marginRight: '10px'}}>
                  <div style={{color: '#0283C8'}}><b>{Number(subTotal).toFixed(3)}</b></div> 
              </div>
          </div>
      </div>
    );

    const columns: any = [
      {
        title: 'Against',
        dataIndex: 'against',
      },
      {
        title: 'Reference',
        dataIndex: 'costingNo',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        align: 'right',
        render: (value, record) => {
          return <span>{Number(value).toFixed(3)} {record.costingCurrency}</span>
        }
      },
    ]

  return (
    <>
      <Layout style={{ backgroundColor: '#F8F8F8', border: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'none',
            marginBottom: '10px',
          }}
        >
          <Space size={5}>
            <a
              href={`/#/${pathToList}`}
              style={{ fontSize: '18px', color: '#6B7280' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}
            >
              {data?.debitCreditNo}
            </span>
            &nbsp;
            <StatusTag status={data?.approvalStatus} />
          </Space>
          <Space size={10}>

            {isPermissionExist([918]) && (<PDFDownloadLink
                  document={<CostingInvoice costingDetails={data} costingName={referenceFeatures}/>}
                  fileName={`${data?.debitCreditNo}.pdf`}
              >
                  <Tooltip placement="topRight" title="Costing Invoice">
                      <FontAwesomeIcon 
                          style={{
                              border: 'none',
                              fontSize: '15px',
                              padding: '7px',
                              borderRadius: '50%',
                              color: '#0295D4',
                              backgroundColor: '#DEECF3',
                          }}
                          icon={faDownload} onClick={() => {
                              // setDummyRefresh(prev => prev + 1)
                          }}
                      />
                  </Tooltip>
              </PDFDownloadLink>)}

            <EditFilled
              style={{
                  border: 'none',
                  fontSize: '15px',
                  padding: '7px',
                  borderRadius: '50%',
                  color: '#0295D4',
                  backgroundColor: '#DEECF3',
                }} 
              onClick={editHandler}/>
          </Space>
        </div>
        <Row gutter={[8,8]}>
          <Col span={16} xs={24} md={24} lg={16}>
              <Card
                title={'Contract Details'}
                headStyle={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#323F49',
                  border: 'none',
                  paddingTop: '10px',
                }}
                bordered={false}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                }}
              >
                <Row>
                  <Col span={16} xs={24} md={24} lg={24}>
                    <Descriptions
                      className="descriptions-label"
                      title=""
                      column={{
                        xxl: 4,
                        xl: 4,
                        lg: 4,
                        md: 3,
                        sm: 1,
                        xs: 1,
                      }}
                      layout="vertical"
                      colon={false}
                      style={{ width: '100%',display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Descriptions.Item label="Note Type" span={1}>
                        <span className="descriptions">{data?.noteType}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Account">
                        <span className="descriptions">{data?.account}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Entry Date">
                        <span className="descriptions">
                          {`${moment(data?.debitCreditEntryDate).format('DD-MMM-YYYY')}`}
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="remarks">
                        <span className="descriptions">{data?.remarks}</span>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                    </Row>
                  </Card>
                  <Col
              style={{ margin: '12px 0px ' }}
            >
              <Card
                className="card-shadow"
                styles={{ body: { padding: '0px 10px 15px' } }}
                style={{ marginBottom: 8 }}
                title={<h6>Transactions</h6>}
              >
                <Table
                  className="contracts-list"
                  dataSource={data?.debitCreditTransactions}
                  columns={columns}
                  pagination={false}
                  footer={footer}
                />

              </Card>
            </Col>
          </Col>
          <Col span={8} xs={24} md={24} lg={8}>
                    <Card
                      className="card-shadow"
                      title={<span className="card-title-md">Price Adjustments</span>}
                      styles={{ body: { padding: '6px 14px' } }}
                    >
                      <Flex justify="space-between" align='center'>
                        <Text className="price-card-text">VAT Applicable</Text>
                        <Text className="price-card-text">{data?.vatApplicable ? "Yes" : "No"}</Text>
                        {/* <Text className="price-card-text">
                          <CountUp
                            start={0}
                            end={Number(data?.taxTotal)}
                            duration={1.75}
                            decimals={3}/>
                        </Text> */}
                      </Flex>
                      <Flex justify="space-between" align='center'>
                        <Text className="price-card-text">Tax Total</Text>
                        <Text className="price-card-text">{Number(data?.taxPercentage).toFixed(3)}</Text>
                        <Text className="price-card-text">{Number(data?.taxTotal).toFixed(3)}</Text>
                      </Flex>
                      <Flex justify="space-between" align='center'>
                        <Text className="price-card-text">TDS</Text>
                        <Text className="price-card-text">{Number(data?.tdsPercentage).toFixed(3)}</Text>
                        <Text className="price-card-text">{Number(data?.tdsTotal).toFixed(3)}</Text>
                      </Flex>

                      <Flex justify="space-between" align='center' style={{ color: referenceFeatures === ReferenceFeatures.DEBIT_NOTE ? 'green' : 'red' }}>
                        <Text className="price-card-text">Total Amount</Text>
                        <Text className="price-card-text">{Number(data?.totalAmount).toFixed(3)}</Text>
                      </Flex>
                    </Card>
                  </Col>
          </Row>
        </Layout>
    </>
  )

}

export default DebitCreditNoteDetailView;