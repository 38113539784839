import { InfoCircleOutlined, DownCircleOutlined, PlusCircleFilled, RightCircleOutlined } from '@ant-design/icons';
import { AccountsService, AdvanceReceiversService, BargeCostingIdReqDto, BargeCostingsService, CommercialCoalCostingService, ContractModesEnum, ContractStatusEnum, CostingInvoiceDto, CostingInvoiceReqDto, CostingInvoiceService, CostingWorkLogsEnum, ExpenseEntryService, LedgerUpdateDto, PaymentNoticeChildDto, PaymentNoticeChildReq, PaymentNoticeGetDataDto, PaymentNoticeService, PerformaCoalCostingService, ReferenceFeatures, StatusUpdateReqDto, TransactionalIdReqDto, VendorAdvService, WorkLogService } from '@exportx/shared-models-and-services';
import { ClassValidator, internationalFormattedValue } from '@exportx/ui-utils';
import { Button, Card, Col, Flex, Form, Input, InputNumber, Row, Select, Descriptions, Typography, Popover, Divider, Space } from 'antd';
import Table from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, StatusTag, useAuthState } from '../../../common';
import { PaymentNoticePageEnum } from './payment-notice';
import ViewFileHandling from '../../../common/file-upload-form/view-files-handling';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApprovalTracking } from '../costing-detail-view';
interface IPaymentNoticeChildProps {
  record: PaymentNoticeGetDataDto;
  type: PaymentNoticePageEnum;
  getParentData: () => void;
  paymentType?: PaymentNoticePageEnum;
  status: string;
}

const { Option } = Select;
const { Text } = Typography;


export const PaymentNoticeChild = (prop: IPaymentNoticeChildProps) => {
  const paymentNoticeService = new PaymentNoticeService();
  const [formRef] = Form.useForm();
  const { authContext } = useAuthState();
  const classValidator = new ClassValidator();
  const { type, record, getParentData, paymentType } = prop;
  const [ledgerAccounts, setLedgerAccounts] = useState([]);
  const [childData, setChildData] = useState<PaymentNoticeChildDto>(null);
  const [accountLedger, setAccountLedger] = useState(null);
  const [hiddenState, setHiddenState] = useState<boolean>(true);
  const commercialService = new CommercialCoalCostingService();
  const performaService = new PerformaCoalCostingService();
  const service = new BargeCostingsService();
  const expensiveService = new ExpenseEntryService();
  const workLogService = new WorkLogService();
  const [kycData, setKycData] = useState<any[]>([]);
  const invoiceService = new CostingInvoiceService();
  const [invoiceData, setInvoiceData] = useState<CostingInvoiceDto[]>([]);
  const [filteredInVoice, setFilteredInvoice] = useState<any>()
  const advanceService = new VendorAdvService();
  const advanceReceivablesService = new AdvanceReceiversService();
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [transactionalData, setTransactionalData] = useState([]);
  const [workLogHistory, setWorkLogHistory] = useState([]);

  const [open, setOpen] = useState<number>(undefined);



  useEffect(() => {
    if (type === PaymentNoticePageEnum.CREATE) {
      const accountsService = new AccountsService();
      const getAllAccountsData = () => {
        accountsService.getAllPmtLedgerAccounts({ plantCode: authContext.defaultPlant }).then((res => {
          if (res.status) {
            setLedgerAccounts(res.data)
          } else {
            setLedgerAccounts([])
          }
        })).catch((err => {
          setLedgerAccounts([])
        }))
      }
      getAllAccountsData();
    } else {
      const req = new BargeCostingIdReqDto(record.costingId, authContext.defaultPlant)
      paymentNoticeService.getPmtNoticeLedgerData(req).then(res => {
        if (res.status) {
          setAccountLedger(res.data);
        } else {
          setAccountLedger(null);
        }
      }).catch(err => {
        console.log(err)
        setAccountLedger(null);
      })
    }
    if (record.expenseType === CostingWorkLogsEnum.DEAD_FREIGHT) {
      const req = new BargeCostingIdReqDto(record.costingId, authContext.defaultPlant);
      getDeadFreightDataByCostingId(req);
    }

    else if (record.expenseType === CostingWorkLogsEnum.EXPENSE_ENTRY) {
      const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
      getExpensivePmtData(req);
    }

    else if (record.expenseType === CostingWorkLogsEnum.DEMURRAGE) {
      const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
      getDemurragePmtData(req);
    }

    else if (record.expenseType === CostingWorkLogsEnum.BARGE_COSTING) {
      const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
      getPaymentNoticeChildDataForBargeCosting(req);
    }

    else if (record.expenseType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || record.expenseType === CostingWorkLogsEnum.PERFORMA_SC_COSTING) {
      const cType: ContractModesEnum = record.expenseType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ? ContractModesEnum.PURCHASE : ContractModesEnum.SALES
      const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant, cType);
      getPmtPerformData(req);
    }

    else if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || record.expenseType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || record.expenseType === CostingWorkLogsEnum.PROCUREMENT_FEES || record.expenseType === CostingWorkLogsEnum.MARKETING_FEES) {
      let contractType: ContractModesEnum;
      if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING) {
        contractType = ContractModesEnum.PURCHASE;
      } else if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING) {
        contractType = ContractModesEnum.SALES;
      } else if (record.expenseType === CostingWorkLogsEnum.PROCUREMENT_FEES) {
        contractType = ContractModesEnum.PROCUREMENT_FEES;
      } else if (record.expenseType === CostingWorkLogsEnum.MARKETING_FEES) {
        contractType = ContractModesEnum.MARKETING_FEES;
      }
      const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant, contractType);
      commercialsData(req);
    } else if (record.expenseType === ReferenceFeatures.ADVANCE_INVOICE) {
      const req = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
      advanceService.getAdvanceCostingData(req).then(res => {
        if (res.status) {
          // res.data.basePrice = Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage));
          // console.log(Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage)))
          setChildData(res.data)
        } else {
          setChildData(undefined)
        }
      })
    } else if (record.expenseType === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      const req = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
      advanceReceivablesService.getReceivablesCostingData(req).then(res => {
        if (res.status) {
          // res.data.basePrice = Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage));
          // console.log(Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage)))
          setChildData(res.data)
        } else {
          setChildData(undefined)
        }
      })
    }
    getAllCostingInvoice();
    getPaymentNoticeTransactionData();
    getWorkLogData();
  }, []);


  const getDeadFreightDataByCostingId = (req) => {
    paymentNoticeService.getDeadFreightDataByCostingId(req).then(res => {
      if (res.status) {

        setChildData(res.data);
      }
    }).catch(err => {
      console.log(err.message);
    });
  };

  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(record.costingId, authContext.defaultPlant, undefined, record.expenseType);
    invoiceService.getAllCostingInvoice(req).then(res => {
      if (res.status) {
        setInvoiceData(res.data?.filter((rec) => rec.status === ContractStatusEnum.ACTIVE));
      } else {
        setInvoiceData([]);
        // console.log(res.internalMessage);

      };
    }).catch(err => console.log(err.message));
  };

  const getPaymentNoticeTransactionData = async () => {
    paymentNoticeService.getPaymentNoticeTransactionsData({ paymentNoticeId: record.paymentNoticeId, plantCode: record.plantCode, status: prop.status }).then(res => {
      if (res.status) {
        setTransactionalData(res.data);
      } else {
        setTransactionalData([]);
      }
    });
  }

  const getWorkLogData = () => {
    const expenseType = record.expenseType === CostingWorkLogsEnum.DISPATCH ? CostingWorkLogsEnum.DEMURRAGE : record.expenseType;

    workLogService.getSavedWorkLogData({ featuresRefId: record.costingId, featuresRefName: expenseType }).then(res => {
      if (res.status) {
        setWorkLogHistory(res.data);
      } else {
        setWorkLogHistory([]);
      }
    }).catch(err => console.log(err.message));
  }

  const getPaymentNoticeChildDataForBargeCosting = (req) => {
    service.getPaymentNoticeChildDataForBargeCosting(req).then(res => {
      if (res.status) {
        setChildData(res.data);
      };
    }).catch(err => {
      console.log(err);
    });
  };

  const getPmtPerformData = (req) => {
    performaService.getPaymentNoticeChildDataPerFormaCosting(req).then(res => {
      if (res.status) {
        setChildData(res.data);
      }
    }).catch((err) => console.log(err.message));
  };

  const commercialsData = (req) => {
    commercialService.getPaymentNoticeChildDataCommercialCosting(req).then(res => {
      if (res.status) {
        setChildData(res.data);
      }
    }).catch(err => {
      console.log(err.message);
    });
  };

  const getExpensivePmtData = (req) => {
    expensiveService.getPaymentNoticeForExpensiveCosting(req).then(res => {
      if (res.status) {
        setChildData(res.data);
      }
    }).catch(err => console.log(err.message));
  };

  const getDemurragePmtData = (req) => {
    service.getPaymentNoticeForDemurrageCosting(req).then(res => {
      if (res.status) {
        setChildData(res.data);
      }
    }).catch(err => console.log(err.message))
  }

  const bankAccountsHandler = (value, children) => {
    if (children === "Bank Accounts") {
      setHiddenState(false);
      paymentNoticeService.getKycDropDownData({ actLedger: childData?.bpId }).then(res => {
        if (res.status) {
          setKycData(res.data);
        } else {
          setKycData([]);
          setHiddenState(true);
        }
      }).catch(err => console.log(err.message, "err message"))
    } else {
      setHiddenState(true);
    }
  };

  const invoiceOnChangeHandler = (value: string) => {
    const againstInvoiceData = invoiceData.filter((rec) => rec.invoiceId === value)[0];
    setFilteredInvoice(againstInvoiceData);
    const remainingInvoice = Number(againstInvoiceData?.invoiceAmount) - transactionalData?.filter((rec) => rec.invoiceNo === value).reduce((a, c) => a + Number(c.transactionAmount), 0);
    setFilteredInvoice((prev) => { return { ...prev, remainingInvoice: remainingInvoice } });
  };


  const ledgerUpdate = (invoiceNo: string) => {
    formRef.validateFields().then(values => {
      if (Number(childData?.totalAmount) < Number(transactionalTotalAmount) + Number(values.transactionAmount)) {
        return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount');
      };
      if (Number(filteredInVoice?.remainingInvoice) < Number(values?.transactionAmount)) {
        return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount');
      };
      const req = new LedgerUpdateDto(record.paymentNoticeId, authContext.user.userName, authContext.defaultPlant, values.actLedger, values.remarks, values.transactionAmount, values.kycId, invoiceNo, childData?.totalAmount);
      paymentNoticeService.updatePaymentNotice(req).then(res => {
        if (res.status) {
          getParentData();
          getPaymentNoticeTransactionData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err);
      });
    });
  };


  const transactionAmountOnchangeHandler = (value: number) => {
    if (Number(childData?.totalAmount) < Number(transactionalTotalAmount) + Number(value)) {
      formRef.setFieldValue('transactionAmount', 0)
      return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount')
    };
    if (Number(filteredInVoice?.remainingInvoice) < Number(value)) {
      formRef.setFieldValue('transactionAmount', 0)
      return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount')
    };
  }

  const statusApprovalAndReject = (status: ContractStatusEnum) => {
    let number = type === PaymentNoticePageEnum.APPROVAL ? 1 : 2;
    formRef.validateFields().then(values => {
      const req: any = new StatusUpdateReqDto(record.paymentNoticeId, status, authContext.user.userName, authContext.defaultPlant, number, number, values.remarks, Number(values.amountPayable));
      paymentNoticeService.statusApprovalAndReject(req).then(res => {
        if (res.status) {
          getParentData();
          getPaymentNoticeTransactionData();
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err);
      });
    });
  };

  const submitHandler = (invoiceNo: string, status: ContractStatusEnum) => {
    if (type === PaymentNoticePageEnum.CREATE) {
      ledgerUpdate(invoiceNo);
    } else {
      statusApprovalAndReject(status);
    };
  };

  const approveTransactionRecord = (transId: string, pmtNoticeId: string, status: ContractStatusEnum) => {
    const req = new TransactionalIdReqDto(pmtNoticeId, transId, authContext.defaultPlant, status);
    paymentNoticeService.pmtTransactionalApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getParentData();
        getPaymentNoticeTransactionData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      };
    }).catch(err => console.log(err.message, 'err message'));
  };

  const transactionalTotalAmount = transactionalData.reduce((a, c) => a + Number(c.transactionAmount), 0);
  const remainingAmount = Number(childData?.totalAmount) - Number(transactionalTotalAmount);

  const invoiceColumns: any = [

    {
      title: 'Invoice No & Date',
      dataIndex: 'invoiceNo',
      fixed: 'left',
      render: (text, record) => {
        return (
          <>
            <Text className='link'>{text}</Text>
            <Typography className='small-text'>{record.invoiceDate !== undefined ? moment(record.invoiceDate).format('DD-MMM-YYYY') : "N/A"}</Typography>
          </>

        )
      },
    },
    {
      title: 'Invoice %',
      dataIndex: 'invoicePercent',
      // width:50,
      render: (text) => <Text className='small-text'>{text}</Text>
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      render: (text) => {
        return (
          <>
            <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
            <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

          </>
        )
      }
    },
    {
      title: 'Balance Amount',
      dataIndex: 'invoiceAmount',
      textAling: 'right',
      render: (text, row) => {
        const remainingInvoice = Number(text) - transactionalData?.filter((rec) => rec.invoiceNo === row.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
        return (
          <>
            <Text className='small-text'>{internationalFormattedValue(remainingInvoice, 2)}</Text>
            <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

          </>
        )
      }
    },
    {
      title: 'Attachment',
      dataIndex: 'filesData',
      render: (value, record) => {
        return <>
          <ViewFileHandling filesData={value.length ? value : []} required={true} />

        </>
      }

    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (text, row) => {
        return <StatusTag status={text} />
      }
    },

    {
      title: 'Actions',
      width: 100,
      fixed: 'right',
      render: (value, row: CostingInvoiceDto, index) => {
        // console.log('row ==>', row)
        formRef.setFieldValue('invoiceNo', row.invoiceId)

        // const invoiceTransactionData = transactionalData.filter((rec) => rec.invoiceNo === row.invoiceId);

        const outstandingInvoice = Number(row?.invoiceAmount ?? 0) - transactionalData?.filter((rec) => rec.invoiceNo === row.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
        const approvalDetails = row.workLog?.filter((e) => e.approvalLevel && e.approvalLevel !== null);
        return <>

          <Popover
            content={<>
              <Form layout='vertical' form={formRef} autoComplete='off' style={{ width: '300px' }}>
                <Form.Item name={"invoiceNo"} style={{ display: 'none' }}>
                  <Input hidden />
                </Form.Item>
                <Form.Item rules={[{ required: true }]}
                  label={paymentType === PaymentNoticePageEnum.RECEIVABLES ? "Receivable by" : 'Payable by'}

                  name="actLedger" style={{ width: '100%' }}>
                  <Select

                    onChange={(value, opt: any) => bankAccountsHandler(value, opt?.grpName)}
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank Account'>
                    {ledgerAccounts.map((a) => {
                      return <Option grpName={a.actLedActGrpId.actGrpName} value={a.actLedId} key={a.actLedId}>{a.actLedName}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label='Receiver Bank'
                  rules={[{ required: false }]}
                  name="kycId" style={{ width: '100%' }}

                >
                  <Select
                    disabled={hiddenState}
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank'>
                    {kycData.map((a) => {
                      return <Option value={a.kycId} key={a.kycId}>{a.bankName}</Option>
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label='Enter Payable Amount'
                  name='transactionAmount'
                  style={{ width: '100%' }}
                  rules={[{ required: true, message: 'Enter Payable Amount' }
                  ]}
                >
                  <InputNumber
                    disabled={isNaN(remainingAmount) || Math.abs(remainingAmount) < 0 ? true : false}
                    style={{ width: '100%' }}
                    max={outstandingInvoice}
                    placeholder='Ex 50000'
                    onChange={transactionAmountOnchangeHandler}
                  />
                </Form.Item>


                <Form.Item
                  label='remarks'
                  name='remarks'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                    },
                    ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                  ]}
                >
                  <Input.TextArea style={{ width: '100%' }} placeholder='Notes' onChange={() => { }} />
                </Form.Item>

                <Divider />

                <Flex align='center' justify='flex-end'>
                  <Space size={10}>

                    <Button type="text" className='link' size='small' onClick={() => setOpen(undefined)}>Cancel</Button>
                    <Button type="primary" className='new-button' size='small' onClick={() => { submitHandler(row.invoiceId, ContractStatusEnum.ACTIVE); setOpen(undefined) }}>Submit For Approval</Button>
                  </Space>
                </Flex>
              </Form>
            </>}
            title="Add Payables"
            trigger="click"
            open={index === open}
            placement="bottom"
          //   onOpenChange={handleOpenChange}
          >
            {type === PaymentNoticePageEnum.CREATE &&
              <Button icon={<PlusCircleFilled />} className="action-icons mr-3" onClick={() => setOpen(index)} />
            }
          </Popover>

          <Popover
            content={<>
              {approvalDetails?.map((log) => (
                <Typography style={{ fontSize: 10 }}><b>{log.approvalLevel}</b> Approved By <b>{log?.createdUser}</b> Approved On <b>{log?.createdAt ? moment(log?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
              ))}
            </>}
            title="Approval Details"
            placement="bottom"
          >
            <Button icon={<InfoCircleOutlined />} className="action-icons" onClick={() => { }} />

          </Popover>

        </>
      }
    }



  ];



  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.invoiceId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const renderItems = (invoiceInfo: CostingInvoiceDto, index, indent, expanded) => {

    formRef.setFieldValue('invoiceNo', invoiceInfo.invoiceId)
    const invoiceTransactionData = transactionalData?.filter((rec) => rec.invoiceNo === invoiceInfo.invoiceId);
    const outstandingInvoice = Number(invoiceInfo?.invoiceAmount ?? 0) - transactionalData?.filter((rec) => rec.invoiceNo === invoiceInfo.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);

    return <Card bordered={false} size='small'>

      <Row gutter={16}>
        {invoiceTransactionData?.map((rec, index) => (
          <Col span={invoiceTransactionData?.length > 1 ? 12 : 24} style={{ marginBottom: 6 }}>
            <Card bordered={false} className="remarks-card no-shadow" styles={{ body: { padding: '10px', } }}>
              <Flex justify="space-between" align="center">
                <Descriptions
                  colon={false}
                  layout="vertical"
                  column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label="Act Ledger">
                    {rec?.actLedger}
                  </Descriptions.Item>
                </Descriptions>
                <StatusTag status={rec?.status} />
              </Flex>
              <Descriptions
                colon={false}
                layout="vertical"
                column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label="Bank Name">
                  {rec?.bankName}
                </Descriptions.Item>
                <Descriptions.Item label="Account Group">
                  {rec?.actGrp}
                </Descriptions.Item>
                <Descriptions.Item label="Paid Date">
                  {rec?.paidDate ? moment(rec?.paidDate).format('YYYY-MM-DD') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Transaction Amount">
                  {internationalFormattedValue(rec.transactionAmount, 3)} {authContext.defaultPlantCurrency}
                </Descriptions.Item>


              </Descriptions>
              {type !== PaymentNoticePageEnum.CREATE && type !== PaymentNoticePageEnum.CLOSED && paymentType !== PaymentNoticePageEnum.RECEIVABLES && (
                <Flex justify="flex-end" align="center">
                  {type === PaymentNoticePageEnum.APPROVAL && <Button size='small' disabled={rec.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.FREEZED)}> <FontAwesomeIcon icon={faCheckCircle} /> Approve</Button>}
                  {type === PaymentNoticePageEnum.RELEASE && <Button size='small' disabled={rec.status === ContractStatusEnum.FREEZED ? false : true} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.CLOSED)}> <FontAwesomeIcon icon={faCheckCircle} /> Release</Button>}
                </Flex>
              )}

              {paymentType === PaymentNoticePageEnum.RECEIVABLES && type === PaymentNoticePageEnum.RELEASE &&
                <Flex justify="flex-end" align="center">
                  <Button type='primary' disabled={rec.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.CLOSED)}> <FontAwesomeIcon icon={faCheckCircle} /> Release</Button>

                </Flex>
              }


            </Card>
          </Col>

        ))}
      </Row>
    </Card>


  };

  return (
    <Card
      bordered={false}
      className="card-shadow"
      styles={{ body: { padding: '0px 10px 15px' } }}
      title={<h6>Invoice Details</h6>}
      extra={<ApprovalTracking history={workLogHistory} />}
    >
      <Table
        columns={invoiceColumns}
        dataSource={invoiceData}
        rowKey={(row) => row.invoiceId}
        pagination={false}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right'
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined
              onClick={(e) => onExpand(record, e)}
            >
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
          )
        }
        scroll={{ x: 1000 }}
        size="small"
      />
    </Card>



  )
}


export default PaymentNoticeChild;