import React, { useEffect, useState } from "react";
import { Button, Card, Flex, Table, Typography } from "antd";
import moment from "moment";
import { AlertMessages, StatusTag, useAuthState } from "../../../common";
import { ContractStatusEnum, CostingInvoiceApproveReqDto, CostingInvoiceReqDto, CostingInvoiceService } from "@exportx/shared-models-and-services";
import { getLocalFormat, internationalFormattedValue } from "@exportx/ui-utils";
import ViewFileHandling from "../../../common/file-upload-form/view-files-handling";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApprovalTracking } from "./approval-tracking";

interface InvoiceDetailsProps {
  costingId: string;
  status: ContractStatusEnum;
  level: ContractStatusEnum;
  invoiceStatus: ContractStatusEnum;
  invApproveDisabled?: boolean;
  includeFreight?: boolean;
  workLog?: any[];
  setDummyRefresh?: React.Dispatch<React.SetStateAction<boolean>>;

}

const { Text } = Typography;

export const InvoiceDetails = (props: InvoiceDetailsProps) => {
  const { costingId, status, level, invApproveDisabled, includeFreight, workLog, setDummyRefresh } = props
  const invoiceService = new CostingInvoiceService();
  const [costingInvoices, setCostingInvoices] = useState([])
  const { authContext } = useAuthState();

  useEffect(() => {
    if (costingId) {
      const req = new CostingInvoiceReqDto(costingId, authContext.defaultPlant);
      invoiceService.getAllCostingInvoice(req).then(res => {
        if (res.status) {
          setCostingInvoices(res.data);
        } else {
          setCostingInvoices([]);
          console.log(res.internalMessage);

        }
      }).catch(err => console.log(err.message));

    }

  }, [costingId])

  const approvalHandler = (id) => {
    const req = new CostingInvoiceApproveReqDto(id, authContext.user.userName, authContext.defaultPlant, status, level)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setDummyRefresh(true);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }
  const rejectedHandler = (id) => {
    const req = new CostingInvoiceApproveReqDto(id, authContext.user.userName, authContext.defaultPlant, ContractStatusEnum.REJECT, level)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setDummyRefresh(true);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }

  const columns: any = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      render: (text, record) => {
        return (
          <>
            <Typography className='link'>{text}</Typography>
            <Typography className='small-text'>{record.invoiceDate !== undefined ? moment(record.invoiceDate).format('DD-MMM-YYYY') : "N/A"}</Typography>
          </>

        )
      },
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      render: (text) => {
        return (
          <>
            <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
            <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

          </>
        )
      }

    },
    ...includeFreight ? [
      {
        title: 'Coal %',
        dataIndex: 'coal_percent',
        key: 'coal_percent',
        render: (text) => <Typography className='small-text'>{text}</Typography>

      },

      {
        title: 'Coal Amount',
        dataIndex: 'coal_amount',
        key: 'coal_amount',
        render: (text) => {
          return (
            <>
              <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
              <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

            </>
          )
        }
      },
      {
        title: 'Freight %',
        dataIndex: 'freight_percent',
        key: 'freight_percent',
        render: (text) => <Typography className='small-text'>{text}</Typography>
      },
      {
        title: 'Freight Amount',
        dataIndex: 'freight_amount',
        key: 'freight_amount',
        render: (text) => {
          return (
            <>
              <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
              <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

            </>
          )
        }
      },
    ] : [
      {
        title: 'Invoice %',
        dataIndex: 'invoicePercent',
        key: 'invoicePercent',
        render: (text) => <Typography className='small-text'>{text}</Typography>

      }
    ],

    {
      title: 'Invoice File',
      dataIndex: 'filesData',
      render: (value, record) => {
        return <>
          <ViewFileHandling filesData={value.length ? value : []} required={true} />

        </>
      }

    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return <>
          <StatusTag status={value} />
        </>
      }
    },

    {
      title: 'Action',
      dataIndex: 'businessNo',
      key: 'businessNo',
      fixed: 'right',
      width: 170,
      render: (value, record) => {
        if (record.status !== ContractStatusEnum.ACTIVE) {
          return (
            <Flex>
              <Button
                disabled={invApproveDisabled}
                size="small"
                className="approve"
                onClick={() => { approvalHandler(record?.invoiceId); }}
              >
                <FontAwesomeIcon icon={faCheckCircle} />
                Approve
              </Button>&nbsp;&nbsp;
              <Button
                disabled={invApproveDisabled}
                size="small"
                className="reject"
                onClick={() => { rejectedHandler(record?.invoiceId); }}
              >
                <FontAwesomeIcon icon={faCircleXmark} />Reject
              </Button>
            </Flex>
          )

        }
      }
    },
  ]

  return (
    <Card
      bordered={false}
      className="card-shadow mb-2"
      styles={{ body: { padding: '0px 10px 15px' } }}
      title={<h6>Invoice Details</h6>}
      extra={<ApprovalTracking history={workLog} />}
    >
      <Table dataSource={costingInvoices}
        columns={columns}
        pagination={false}
        className='contracts-list'
        size="small"
        scroll={{ x: 1200 }}

        bordered={false}

      />
    </Card>

  )

}