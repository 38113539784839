import {
  BargeCostingsService,
  BPFCostingRequestIdDto,
  ContractStatusEnum,
  GetDataDemurrageDto,
  GetExpApprovalDataDto,
  PaymentTypeEnum,
} from '@exportx/shared-models-and-services';
import {
  Input,
  Card,
  Table,
  Form,
  Row,
  Col,
  List,
  Button,
  Popconfirm,
  Tooltip,
  Flex,
  Space,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { SequenceUtils, internationalFormattedValue } from '@exportx/ui-utils';
import { RightCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  InvoiceDetails,
  DemurrageDispatchDetails,
} from '../costing-detail-view';
import { AlertMessages, useAuthState } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
interface IProps {
  type?: ContractStatusEnum;
  paymentType?: PaymentTypeEnum;
}

export const DemurrageApproval = (props: IProps) => {
  const { type, paymentType } = props;

  const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();
  const bargeCostingService = new BargeCostingsService();
  const { authContext } = useAuthState();
  const [searchedText, setSearchedText] = useState('');
  const [expandedIndex, setExpandedIndex] = useState([]);

  const [formRef] = Form.useForm();

  useEffect(() => {
    getDemurrageData();
  }, []);

  const getDemurrageData = () => {
    const status =
      type === ContractStatusEnum.BUSINESS_APPROVAL
        ? ContractStatusEnum.PENDING_APPROVAL
        : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    const req = new GetExpApprovalDataDto(
      paymentType,
      authContext.defaultPlant,
      status,
    );
    bargeCostingService
      .getDemurrageDataForApproval(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.bgDemurrageId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const approveStatus =
    type === ContractStatusEnum.BUSINESS_APPROVAL
      ? ContractStatusEnum.ACTIVE
      : ContractStatusEnum.ACTIVE;

  const approveDemurrage = (costingId: string, status: ContractStatusEnum) => {
    formRef
      .validateFields()
      .then((values) => {
        const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
          costingId,
          values['remarks'],
          status,
          authContext.user.userName,
          authContext.defaultPlant,
          type,
        );

        console.log('req =>', req);
        bargeCostingService
          .demurrageApproval2(req)
          .then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              getDemurrageData();
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderItems = (record: any) => {
    return (
      <DemurrageDispatchDetails
        costingId={record.bgDemurrageId}
        remainingAmount={0}
      >
        <Card
          bordered={false}
          className="card-shadow mb-2"
          styles={{ body: { padding: '0px 10px 15px' } }}
        >
          <Form
            form={formRef}
            // initialValues={initialValues}
            layout="vertical"
            autoComplete="off"
          >
            <Row className="p-2">
              <Form.Item
                style={{ width: '100%' }}
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: 'Enter Work Log', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                  },
                ]}
              >
                <Input.TextArea placeholder="Notes" />
              </Form.Item>
            </Row>
            <Row>
              <Col span={16}>
                <Row>
                  <Col span={3}>Worklog :</Col>
                  <Col span={20}>
                    {record?.workLog?.length > 0 && (
                      <List
                        bordered
                        dataSource={record?.workLog ? record?.workLog : []}
                        renderItem={(item, index) => (
                          <List.Item key={index} style={{ width: '100%' }}>
                            <div>
                              <span>{index + 1}</span>
                              <span>.</span>&nbsp;{item.workLog}
                            </div>
                          </List.Item>
                        )}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL && (
              <Flex align="middle" justify="flex-end">
                <Space size={10}>
                  <Button
                    size="small"
                    className="approve"
                    onClick={() =>
                      approveDemurrage(record?.bgDemurrageId, approveStatus)
                    }
                  >
                    {' '}
                    <FontAwesomeIcon icon={faCheckCircle} /> Approve
                  </Button>

                  <Popconfirm
                    onConfirm={(e) => {
                      approveDemurrage(
                        record?.bgDemurrageId,
                        ContractStatusEnum.CANCELLED,
                      );
                    }}
                    title={'Are you sure to reject'}
                  >
                    <Tooltip placement="top" title={'Reject'}>
                      <Button size="small" className="reject">
                        {' '}
                        <FontAwesomeIcon icon={faCircleXmark} />
                        Reject
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Space>
              </Flex>
            )}
          </Form>
        </Card>

        <InvoiceDetails
          costingId={record.bgDemurrageId}
          status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
          level={ContractStatusEnum.BUSINESS_APPROVAL}
          invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
          invApproveDisabled={
            record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL
          }
        />
      </DemurrageDispatchDetails>
    );
  };

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Demurrage Ref #',
      dataIndex: 'costingNo',
      width: '20%',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'businessNumber',
      // width: '40%',
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`;
        return (
          <>
            <a href={href} className="link">
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Cost Type',
      dataIndex: 'allowedTimeToLoad', //timeCompletedForLoadMV
      render: (value, record) => {
        let costType: string = '';
        if (Number(value) > Number(record.timeCompletedForLoadMV)) {
          costType = 'Dispatch';
        } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
          costType = 'Demurrage';
        } else {
          costType = ' ';
        }
        return <>{costType}</>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'demurrageDate',
      render: (value, record) => {
        return <>{moment(value)?.format('DD-MM-YYYY')}</>;
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'account',
    },
    {
      title: 'Business Partner',
      dataIndex: 'bpName',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.quoteCurrency}`}</span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card
        className="card-radius"
        title={
          <Row className="mt-16">
            <Input.Search
              placeholder="Search Orders"
              allowClear
              value={searchedText}
              onChange={(e) => setSearchedText(e.target.value)}
              className="input-search"
            />
          </Row>
        }
      >
        <Table
          size="small"
          expandIconColumnIndex={0}
          className="contracts-list"
          rowKey={(record) => record.bgDemurrageId}
          columns={tableColumns}
          dataSource={gridData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
                Expand
              </RightCircleOutlined>
            )
          }
          pagination={false}
          scroll={{ x: 500 }}
          bordered={false}
        />
      </Card>
    </>
  );
};
export default DemurrageApproval;
