import {
  DeleteOutlined,
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
} from '@ant-design/icons';
import {
  BargeCostingsService,
  BgDemurrageIdReqDto,
  ContractStatusEnum,
  ContractStatusReqDto,
  GetDataDemurrageDto,
  PaymentTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
    Button,
  Card,
  Col,
  Divider,
  Flex,
  FormInstance,
  Input,
  Popconfirm,
  Row,
  Table,
  TableColumnsType,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../common';
import AddInvoiceDrawer from '../add-invoice-drawer';
import Invoice from '../invoice/invoice';
import { getLocalFormat, internationalFormattedValue } from '@exportx/ui-utils';
import { SequenceUtils } from '@exportx/ui-utils';
import { useNavigate } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import DemurrageApproval from './demurrage-approval';
interface IProps {
  gridData?: GetDataDemurrageDto[];
  getDemurrageData?: () => void;
  setInitialValues?: (value: any) => void;
  demurrageApproval?: boolean;
  formRef?: FormInstance<any>;
  accessId?: any;
}

export const DemurrageList = (props: IProps) => {
  const navigate = useNavigate();

  const bargeCostingService = new BargeCostingsService();

  const { authContext } = useAuthState();
  const { setInitialValues, demurrageApproval, formRef, accessId } = props;
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [searchedText, setSearchedText] = useState('');
  const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();

  useEffect(() => {
    getDemurrageData();
  }, []);

  const getDemurrageData = () => {
    const req = new ContractStatusReqDto(undefined, authContext.defaultPlant);
    bargeCostingService
      .getDemurrageData(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteDemurrage = (bgDemurrageId) => {
    const req = new BgDemurrageIdReqDto(
      bgDemurrageId,
      authContext.defaultPlant,
    );
    bargeCostingService
      .deleteDemurrage(req)
      .then((res) => {
        if (res.status) {
          getDemurrageData();
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const tableColumns: TableColumnsType<any> = [
    // {
    //     title: 'DemurrageAction',
    //     width: '100%',
    //     align: 'center',
    //     render: (text, rowData) => (
    //         <span>
    //             {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([accessId?.demurrageEdit]) && (

    //             <Tooltip placement="topRight" title="Edit">
    //                 <EditOutlined type="edit"
    //                     onClick={() =>  navigate(`/demurrage?costingId=${rowData.bgDemurrageId}`)}
    //                     style={{ color: '#1890ff', fontSize: '20px' }}
    //                 /></Tooltip>)}

    //             <Divider type="vertical" />
    //             {isPermissionExist([accessId?.demurrageDelete]) && (
    //             <Popconfirm
    //                 onConfirm={e => {
    //                     deleteDemurrage(rowData.bgDemurrageId)
    //                 }}
    //                 title={'Are you sure to Delete Record ?'}>
    //                 <Tooltip placement="topRight" title="Delete">
    //                     <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
    //                 </Tooltip>
    //             </Popconfirm>)}
    //             <Divider type="vertical" />
    //             <Tooltip placement="topRight" title="Add Invoice">
    //                {isPermissionExist([accessId?.view]) && (
    //                 <PlusSquareTwoTone onClick={() => {
    //                     setDrawerOpen(true);
    //                     setCostingId(rowData.bgDemurrageId)
    //                     setCostingId((prev) => { return { ...prev, costingId: rowData.bgDemurrageId, totalAmount: rowData.totalSumAmount } });

    //                 }}
    //                     style={{ color: '#1890ff', fontSize: '20px' }}
    //                 />)}
    //                 <Divider type='vertical'></Divider>
    //                 <PDFDownloadLink
    //                     document={<Invoice invoice={''} demurrageName={''} costingName={ReferenceFeatures.MV_DEMURRAGE} />}
    //                     fileName={`${rowData?.costingNo}.pdf`}
    //                 >
    //                     <Tooltip placement="topRight" title="Invoice">
    //                         <FilePdfTwoTone style={{ color: '#1890ff', fontSize: '20px' }}
    //                         />
    //                     </Tooltip>
    //                 </PDFDownloadLink>
    //             </Tooltip>

    //         </span>
    //     )

    // },
    {
      title: 'Ref',
      dataIndex: 'costingNo',
      // width: '40%',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
            <a
              className="link"
              href={`/#/demurrage-detail-view?costing_id=${record.bgDemurrageId}`}
            >
              {record.costingNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'businessNumber',
      // width: '40%',
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`;
        return (
          <>
            <a href={href} className="link">
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Business Partner',
      dataIndex: 'bpName',
      // width: '40%',
    },
    {
      title: 'Cost Type',
      dataIndex: 'allowedTimeToLoad', //timeCompletedForLoadMV
      render: (value, record) => {
        let costType: string = '';
        if (Number(value) > Number(record.timeCompletedForLoadMV)) {
          costType = 'Dispatch';
        } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
          costType = 'Demurrage';
        } else {
          costType = ' ';
        }
        return <>{costType}</>;
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'account',
      // width: '40%',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      // width: '40%',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.baseCurrency}`}</span>
          </>
        );
      },
    },
    {
        title: 'Status',
        dataIndex: 'approvalStatus',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan,
        }),
        render: (value, _) => {
          return (
            <>
              <StatusTag status={value} />
            </>
          );
        },
      },
  ];

  return (
    <>
      <Card className="card-radius">
        <Tabs>
          {isPermissionExist([727]) && (
            <TabPane tab="Costing Creation" key="costing-creation">
              <Row justify={'space-between'} className='mb-12 mt-8'>
                <Col>
                  <Flex gap={10}>
                    <Input.Search
                    className='input-search'
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearchedText(e.target.value);
                      }}
                      onSearch={(value) => {
                        setSearchedText(value);
                      }}
                      // style={{ width: 200, float: "right", display: `${demurrageApproval ? "none" : ""}` }}
                    />
                  </Flex>
                </Col>
                <Col>
                <Button className='new-button' onClick={()=>navigate('/create-demurrage')}>New Demurrage</Button>
                </Col>
              </Row>

              <Table
                className="contracts-list"
                scroll={{ x: 500 }}
                style={{ display: `${demurrageApproval ? 'none' : ''}` }}
                columns={tableColumns}
                dataSource={gridData}
                pagination={{
                  showTotal: (total) => (
                    <span className="page-total-items">
                      Total {total} items
                    </span>
                  ),
                  showSizeChanger: true,
                  pageSizeOptions: ['25', '50', '100'],
                  defaultPageSize: 25,
                  style: {
                    paddingTop: '12px',
                  },
                }}
                rowClassName={(record, index) =>
                  record?.approvalStatus === ContractStatusEnum.CANCELLED
                    ? 'bg-warning'
                    : ''
                }
              ></Table>
            </TabPane>
          )}
          {isPermissionExist([730]) && (
            <TabPane tab="Business Approval" key="business-approval">
              <DemurrageApproval
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                paymentType={PaymentTypeEnum.PAYABLE}
              />
            </TabPane>
          )}
          {isPermissionExist([732]) && (
            <TabPane tab="Management Review" key="management-review">
              <DemurrageApproval
                type={ContractStatusEnum.MANAGEMENT_APPROVAL}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>
      <AddInvoiceDrawer
        referenceFeatures={ReferenceFeatures.MV_DEMURRAGE}
        key={dummyRefresh}
        setDummyRefresh={setDummyRefresh}
        openDrawer={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        costingId={costingId}
        accessId={accessId}
      />
    </>
  );
};
export default DemurrageList;
